import React from 'react';
import { Text, View } from '@react-pdf/renderer';
import { intlShape } from '../../util/reactIntl';
import { propTypes } from '../../util/types';
import { lineItemCustomerCommissionTaxRefundMaybe } from '../../util/bookingBreakdown/lineItemCustomerCommissionTaxRefundMaybe';
import { styles } from '../Invoice/InvoiceStyles';
import { bool } from 'prop-types';

const styleSheet = styles;

const LineItemCustomerCommissionRefundTaxMaybePdf = props => {
  const { transaction, isCustomer, intl } = props;
  const { label, price } = lineItemCustomerCommissionTaxRefundMaybe(transaction, isCustomer, intl);

  return isCustomer && price ? (
    <View style={styleSheet.row}>
      <View style={styleSheet.tColumnLeftWide}>
        <Text>{label}</Text>
      </View>
      <View style={styleSheet.rightAligned}>
        <Text>{price}</Text>
      </View>
    </View>
  ) : null;
};

LineItemCustomerCommissionRefundTaxMaybePdf.propTypes = {
  transaction: propTypes.transaction.isRequired,
  isCustomer: bool.isRequired,
  intl: intlShape,
};

export default LineItemCustomerCommissionRefundTaxMaybePdf;
