import React, { Component } from 'react';
import { string, func } from 'prop-types';
import { FormattedMessage, intlShape, injectIntl } from '../../util/reactIntl';
import classNames from 'classnames';
import { lazyLoadWithDimensions } from '../../util/contextHelpers';
import { LINE_ITEM_DAY, LINE_ITEM_NIGHT, propTypes } from '../../util/types';
import { formatMoney } from '../../util/currency';
import { ensureListing, ensureUser } from '../../util/data';
import { richText } from '../../util/richText';
import { createSlug } from '../../util/urlHelpers';
import config from '../../config';
import { IconReviewStar, NamedLink, PillTag, ResponsiveImage } from '../../components';

import css from './ListingCard.module.css';

import superOwnerIcon from '../../assets/super-owner-icon.png';

const MIN_LENGTH_FOR_LONG_WORDS = 10;

// TODO save and use from a common place
const { types } = require('sharetribe-flex-sdk');
const { Money } = types;
const CUSTOMER_COMMISSION_PERCENTAGE = 18;
const CUSTOMER_COMMISSION_TAX_PERCENTAGE = 1.8;

const priceData = (price, intl) => {
  if (price && price.currency === config.currency) {
    const formattedPrice = formatMoney(intl, price);
    return { formattedPrice, priceTitle: formattedPrice };
  } else if (price) {
    return {
      formattedPrice: intl.formatMessage(
        { id: 'ListingCard.unsupportedPrice' },
        { currency: price.currency }
      ),
      priceTitle: intl.formatMessage(
        { id: 'ListingCard.unsupportedPriceTitle' },
        { currency: price.currency }
      ),
    };
  }
  return {};
};

const priceDataWithGSTandFee = (price, intl) => {
  if (price && price.currency === config.currency) {
    const commissionAmount = (price.amount * CUSTOMER_COMMISSION_PERCENTAGE) / 100;
    const gstAmount = (price.amount * CUSTOMER_COMMISSION_TAX_PERCENTAGE) / 100;
    const totalAmount = price.amount + commissionAmount + gstAmount;

    const updatedPrice = new Money(totalAmount, price.currency);
    const formattedPrice = formatMoney(intl, updatedPrice);

    return {
      formattedPrice,
      priceTitle: formattedPrice,
    };
  } else if (price) {
    return {
      formattedPrice: intl.formatMessage(
        { id: 'ListingCard.unsupportedPrice' },
        { currency: price.currency }
      ),
      priceTitle: intl.formatMessage(
        { id: 'ListingCard.unsupportedPriceTitle' },
        { currency: price.currency }
      ),
    };
  }
  return {};
};

class ListingImage extends Component {
  render() {
    return <ResponsiveImage {...this.props} />;
  }
}
const LazyImage = lazyLoadWithDimensions(ListingImage, { loadAfterInitialRendering: 3000 });

export const ListingCardComponent = props => {
  const { className, rootClassName, intl, listing, renderSizes, setActiveListing } = props;
  const classes = classNames(rootClassName || css.root, className);
  const currentListing = ensureListing(listing);
  const id = currentListing.id.uuid;
  const { title = '', price } = currentListing.attributes;
  const slug = createSlug(title);
  const author = ensureUser(listing.author);
  const authorName = author.attributes.profile.displayName;
  const businessName = author.attributes.profile.publicData?.businessName;
  const isSuperOwner = author.attributes.profile.publicData?.superOwner || false;
  const sharedBy = businessName ? businessName : authorName;
  const firstImage =
    currentListing.images && currentListing.images.length > 0 ? currentListing.images[0] : null;

  const { formattedPrice, priceTitle } = config.custom.featureToggles[config.env].displayTotalPrice
    ? priceDataWithGSTandFee(price, intl)
    : priceData(price, intl);

  const unitType = config.bookingUnitType;
  const isNightly = unitType === LINE_ITEM_NIGHT;
  const isDaily = unitType === LINE_ITEM_DAY;

  const averageRating = currentListing.author.attributes.profile.publicData?.reviewStats?.avgReviewRating.toFixed(
    2
  );

  const ownerRating = averageRating ? (
    <span className={css.authorRatingWrapper}>
      {isSuperOwner ? (
        <img src={superOwnerIcon} alt="super owner icon" className={css.superOwnerIcon} />
      ) : null}
      <IconReviewStar rootClassName={css.star} />
      <span className={css.rating}>{averageRating}</span>
    </span>
  ) : null;

  const unitTranslationTotalMaybe = config.custom.featureToggles[config.env].displayTotalPrice
    ? 'ListingCard.perUnitTotal'
    : 'ListingCard.perUnit';

  const unitTranslationKey = isNightly
    ? 'ListingCard.perNight'
    : isDaily
    ? 'ListingCard.perDay'
    : unitTranslationTotalMaybe;

  const minDuration = listing.attributes.publicData.minDuration || 0;
  const minDurationLabel = config.custom.minDurationOptions.find(
    option => option.hours === parseInt(minDuration)
  )?.listingCardLabel;
  return (
    <NamedLink className={classes} name="ListingPage" params={{ id, slug }}>
      <div
        className={css.threeToTwoWrapper}
        onMouseEnter={() => setActiveListing(currentListing.id)}
        onMouseLeave={() => setActiveListing(null)}
      >
        <div className={css.aspectWrapper}>
          <LazyImage
            rootClassName={css.rootForImage}
            alt={title}
            image={firstImage}
            variants={['landscape-crop', 'landscape-crop2x']}
            sizes={renderSizes}
          />
        </div>
        {listing.attributes.publicData.subscription === 'premium' ? (
          <PillTag text="Premium" type="standard" />
        ) : null}
      </div>
      <div className={css.info}>
        <div className={css.price}>
          <div className={css.priceValue} title={priceTitle}>
            {formattedPrice}
          </div>
          <div className={css.perUnit}>
            <FormattedMessage id={unitTranslationKey} />
          </div>
          {!!minDuration && (
            <div className={css.minDuration}>
              <FormattedMessage
                id="ListingCard.minDuration"
                values={{ minDuration: minDurationLabel }}
              />
            </div>
          )}
        </div>
        <div className={css.mainInfo}>
          <div className={css.title}>
            {richText(title, {
              longWordMinLength: MIN_LENGTH_FOR_LONG_WORDS,
              longWordClass: css.longWord,
            })}
          </div>
          <div className={css.authorInfo}>
            <div>
              <FormattedMessage id="ListingCard.hostedBy" values={{ sharedBy }} />
            </div>
            {ownerRating}
          </div>
        </div>
      </div>
    </NamedLink>
  );
};

ListingCardComponent.defaultProps = {
  className: null,
  rootClassName: null,
  renderSizes: null,
  setActiveListing: () => null,
};

ListingCardComponent.propTypes = {
  className: string,
  rootClassName: string,
  intl: intlShape.isRequired,
  listing: propTypes.listing.isRequired,

  // Responsive image sizes hint
  renderSizes: string,

  setActiveListing: func,
};

export default injectIntl(ListingCardComponent);
