import React from 'react';
import { bool } from 'prop-types';
import { intlShape } from '../../util/reactIntl';
import { propTypes } from '../../util/types';
import { lineItemLiabilityReductionRefundMaybe } from '../../util/bookingBreakdown/lineItemLiabilityReductionRefundMaybe';
import config from '../../config';

import css from './BookingBreakdown.module.css';

const LineItemLiabilityReductionRefundMaybe = props => {
  const { transaction, isCustomer, intl } = props;

  const { label, price } = lineItemLiabilityReductionRefundMaybe(transaction, isCustomer, intl);

  return isCustomer && !!price && config.custom.featureToggles[config.env].liabilitySelection ? (
    <>
      <div className={css.lineItem}>
        <span className={css.itemLabel}>{label}</span>
        <span className={css.itemValue}>{`${price}`}</span>
      </div>
    </>
  ) : null;
};

LineItemLiabilityReductionRefundMaybe.propTypes = {
  transaction: propTypes.transaction.isRequired,
  isCustomer: bool.isRequired,
  intl: intlShape.isRequired,
};

export default LineItemLiabilityReductionRefundMaybe;
