import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import config from '../../config';

import css from './LiabilityOptionsForm.module.css';

const LiabilityOptionsForm = ({ className, onLiabilityChange, selectedLiability }) => {
  return (
    <div className={classNames(css.liabilityOptionsForm, className)}>
      <div className={css.liabilityButtons}>
        {config.custom.liabilityOptions.map(option => (
          <button
            key={option.liability}
            type="button"
            className={classNames(css.liabilityButton, {
              [css.selected]: option.liability === selectedLiability?.liability,
            })}
            onClick={() => onLiabilityChange(option)}
          >
            <strong>{option.label}</strong>
            <br />${option.liability} excess, ${option.amount.toFixed(2)} per day
          </button>
        ))}
      </div>
    </div>
  );
};

LiabilityOptionsForm.propTypes = {
  className: PropTypes.string,
  onLiabilityChange: PropTypes.func.isRequired,
};

export default LiabilityOptionsForm;
