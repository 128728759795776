import React from 'react';
import { Field } from 'react-final-form';
import { FieldSelect } from '..';

import css from './FieldOptionSelect.module.css';

const FieldOptionSelect = props => {
  const { name, id, initialValue, placeholder, options, parseValue } = props;

  return (
    <Field
      name={name}
      id={id}
      initialValue={initialValue}
      parse={parseValue || (value => Number(value))}
    >
      {({ input }) => (
        <FieldSelect {...input} className={css.category}>
          <option disabled value="">
            {placeholder}
          </option>
          {options.map(option => (
            <option key={option.key} value={option.value}>
              {option.label}
            </option>
          ))}
        </FieldSelect>
      )}
    </Field>
  );
};

export default FieldOptionSelect;
