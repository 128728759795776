import React, { Component } from 'react';
import moment from 'moment-timezone/builds/moment-timezone-with-data-10-year-range.min';
import { func, object, string, number } from 'prop-types';
import classNames from 'classnames';
import config from '../../config';
import { intlShape } from '../../util/reactIntl';
import {
  expectedNumberOfAvailableTimes,
  getStartHours,
  getEndHours,
  getSharpHours,
  isInRange,
  isSameDate,
  isSameDay,
  isSameMonth,
  isNextMonth,
  isDayMomentInsideRange,
  isEndDateWithinPaidForRange,
  resetToStartOfDay,
  timeOfDayFromLocalToTimeZone,
  timeOfDayFromTimeZoneToLocal,
  timeOfDayStringTo12HourClock,
  add30MinsToTimeOfDayString,
  dateIsAfter,
  findNextBoundary,
  timestampToDate,
  localizeAndFormatTime,
  monthIdStringInTimeZone,
  getMonthStartInTimeZone,
  dateInTimeZone,
  nextMonthFn,
  prevMonthFn,
} from '../../util/dates';
import {
  hasOpeningHoursForDate,
  getOpeningHoursForDate,
  openingHoursForDate,
  isToday,
} from '../../util/string-dates';
import { propTypes } from '../../util/types';
import { bookingDateRequired } from '../../util/validators';
import { FieldDateInput, FieldSelect } from '../../components';
import * as validators from '../../util/validators';

import NextMonthIcon from './NextMonthIcon';
import PreviousMonthIcon from './PreviousMonthIcon';
import css from './FieldDateAndTimeInput.module.css';
import { sortTimeSlots } from '../../util/timeslots';

// MAX_TIME_SLOTS_RANGE is the maximum number of days forwards during which a booking can be made.
// This is limited due to Stripe holding funds up to 90 days from the
// moment they are charged:
// https://stripe.com/docs/connect/account-balances#holding-funds
//
// See also the API reference for querying time slots:
// https://www.sharetribe.com/api-reference/marketplace.html#query-time-slots

const MAX_TIME_SLOTS_RANGE = config.dayCountAvailableForBooking;

const TODAY = new Date();

const endOfRange = (date, timeZone) => {
  return resetToStartOfDay(date, timeZone, MAX_TIME_SLOTS_RANGE - 1);
};

export const getAvailableStartTimes = (
  intl,
  timeZone,
  bookingStart,
  timeSlotsOnSelectedDate,
  openingHours,
  leadTime,
  paidForQuantity
) => {
  if (timeSlotsOnSelectedDate.length === 0 || !timeSlotsOnSelectedDate[0] || !bookingStart) {
    return [];
  }
  const bookingStartDate = resetToStartOfDay(bookingStart, timeZone);

  // Calculate lead time cut-off from current time
  const now = intl.now ? moment(intl.now()) : moment();
  const leadTimeCutoff = now
    .clone()
    .tz(timeZone)
    .add(leadTime, 'minute');

  const allHours = timeSlotsOnSelectedDate.reduce((availableHours, t) => {
    const startDate = t.attributes.start;
    const endDate = t.attributes.end;
    const nextDate = resetToStartOfDay(bookingStartDate, timeZone, 1);

    // If the start date is after timeslot start, use the start date.
    // Otherwise use the timeslot start time.
    const startLimit = dateIsAfter(bookingStartDate, startDate) ? bookingStartDate : startDate;

    // If date next to selected start date is inside timeslot use the next date to get the hours of full day.
    // Otherwise use the end of the timeslot.
    const endLimit = dateIsAfter(endDate, nextDate)
      ? moment(nextDate).subtract(1, 'minutes') // setting to 23:59 on the same day instead of 24:00 to avoid day-of-week confusion in opening hours (avoids the bug of showing 00:00 if the following day's opening hours start at 00:00)
      : moment(endDate).subtract(30, 'minutes');

    let hours = getStartHours(intl, timeZone, startLimit, endLimit, openingHours);

    const isChangingBooking = !!paidForQuantity;
    if (!isChangingBooking) {
      // Filter out times that are within the lead time cut-off
      hours = hours.filter(hour => moment(hour.timestamp).isAfter(leadTimeCutoff));
    }
    return availableHours.concat(hours);
  }, []);
  return allHours;
};

export const getAvailableEndTimes = (
  intl,
  timeZone,
  bookingStartTime,
  bookingEndDate,
  selectedTimeSlot,
  openingHours,
  paidForQuantity,
  minDuration
) => {
  if (
    !selectedTimeSlot ||
    !selectedTimeSlot.attributes ||
    !bookingEndDate ||
    !bookingStartTime ||
    !openingHours
  ) {
    return [];
  }

  const startTimeAsMoment = moment(timestampToDate(bookingStartTime));
  const minReturnTimeAsMoment = !!paidForQuantity
    ? startTimeAsMoment
    : startTimeAsMoment.add(minDuration, 'hours');
  const minReturnTime = minReturnTimeAsMoment.toDate();

  const endOfTimeSlot = selectedTimeSlot.attributes.end;
  const bookingStartTimeAsDate = timestampToDate(bookingStartTime);

  const dayAfterBookingEnd = resetToStartOfDay(bookingEndDate, timeZone, 1);
  const dayAfterBookingStart = resetToStartOfDay(bookingStartTimeAsDate, timeZone, 1);
  const startOfEndDay = resetToStartOfDay(bookingEndDate, timeZone);

  let startLimit;
  let endLimit;

  const isSingleDayBooking = dateIsAfter(bookingStartTimeAsDate, startOfEndDay);
  if (isSingleDayBooking) {
    startLimit = minReturnTime;
    endLimit = dateIsAfter(dayAfterBookingStart, endOfTimeSlot)
      ? endOfTimeSlot
      : dayAfterBookingStart;
  } else {
    startLimit = minReturnTimeAsMoment.isAfter(startOfEndDay) ? minReturnTime : startOfEndDay;

    const maximumEndTimeAsDate = !!paidForQuantity
      ? moment(bookingStartTimeAsDate).add(paidForQuantity, 'days')
      : undefined;

    if (!!paidForQuantity && isSameDay(maximumEndTimeAsDate, bookingEndDate, timeZone)) {
      // if selected end date is the same date as the last bookable day (when changing booking)
      // ensure that the trailer cannot be returned later than the pick-up time
      endLimit = maximumEndTimeAsDate;
    } else {
      // If the selected end date is on the same day as timeslot end, use the timeslot end.
      // Else use the start of the next day after selected date.
      endLimit =
        isSameDate(resetToStartOfDay(endOfTimeSlot, timeZone), startOfEndDay) &&
        dateInTimeZone(startOfEndDay, timeZone) !== 1 // ignore the timeslot end if we have selected the first of the month; otherwise we can't see any available times to return on the 1st of the month in future months (after the next month) – not clear why.
          ? endOfTimeSlot
          : dayAfterBookingEnd;
    }
  }

  const isSingleDayRange = isSameDay(bookingStartTimeAsDate, bookingEndDate, timeZone);

  // setting to 23:59 on the same day instead of 24:00 to avoid day-of-week confusion in opening hours (avoids the bug of showing 00:00 if the following day's opening hours start at 00:00);
  if (moment(endLimit).format('HH:mm') === '00:00') {
    endLimit = moment(endLimit)
      .subtract(1, 'minutes')
      .toDate();
  }

  return isSingleDayRange
    ? getEndHours(intl, timeZone, startLimit, endLimit, openingHours)
    : getSharpHours(intl, timeZone, startLimit, endLimit, openingHours);
};

const getTimeSlots = (timeSlots, date, timeZone) => {
  return timeSlots && timeSlots[0]
    ? timeSlots.filter(t => isInRange(date, t.attributes.start, t.attributes.end, 'day', timeZone))
    : [];
};

// Use start date to calculate the first possible start time or times, end date and end time or times.
// If the selected value is passed to function it will be used instead of calculated value.
const getAllTimeValues = (
  intl,
  timeZone,
  timeSlots,
  startDate,
  selectedStartTime,
  selectedEndDate,
  openingHours,
  leadTime,
  paidForQuantity,
  minDuration
) => {
  const startTimes = selectedStartTime
    ? []
    : getAvailableStartTimes(
        intl,
        timeZone,
        startDate,
        getTimeSlots(timeSlots, startDate, timeZone),
        openingHours,
        leadTime,
        paidForQuantity
      );

  // Value selectedStartTime is a string when user has selected it through the form.
  // That's why we need to convert also the timestamp we use as a default
  // value to string for consistency. This is expected later when we
  // want to compare the sartTime and endTime.
  const startTime = selectedStartTime
    ? selectedStartTime
    : startTimes.length > 0 && startTimes[0] && startTimes[0].timestamp
    ? startTimes[0].timestamp.toString()
    : null;

  const startTimeAsDate = startTime ? timestampToDate(startTime) : null;

  // Note: We need to remove 1ms from the calculated endDate so that if the end
  // date would be the next day at 00:00 the day in the form is still correct.
  // Because we are only using the date and not the exact time we can remove the
  // 1ms.
  const endDate = selectedEndDate
    ? selectedEndDate
    : startTimeAsDate
    ? new Date(findNextBoundary(timeZone, startTimeAsDate).getTime() - 1)
    : null;

  const selectedTimeSlot = timeSlots.find(t =>
    isInRange(startTimeAsDate, t.attributes.start, t.attributes.end)
  );

  const endTimes = getAvailableEndTimes(
    intl,
    timeZone,
    startTime,
    endDate,
    selectedTimeSlot,
    openingHours,
    minDuration
  );

  // We need to convert the timestamp we use as a default value
  // for endTime to string for consistency. This is expected later when we
  // want to compare the sartTime and endTime.
  const endTime =
    endTimes.length > 0 && endTimes[0] && endTimes[0].timestamp
      ? endTimes[0].timestamp.toString()
      : null;

  return { startTime, endDate, endTime, selectedTimeSlot };
};

const getMonthlyTimeSlots = (monthlyTimeSlots, date, timeZone) => {
  const monthId = monthIdStringInTimeZone(date, timeZone);

  const unsorted =
    !monthlyTimeSlots || Object.keys(monthlyTimeSlots).length === 0
      ? []
      : monthlyTimeSlots[monthId] && monthlyTimeSlots[monthId].timeSlots
      ? monthlyTimeSlots[monthId].timeSlots
      : [];

  return sortTimeSlots(unsorted);
};

const Next = props => {
  const { currentMonth, timeZone } = props;
  const nextMonthDate = nextMonthFn(currentMonth, timeZone);

  return dateIsAfter(nextMonthDate, endOfRange(TODAY, timeZone)) ? null : <NextMonthIcon />;
};
const Prev = props => {
  const { currentMonth, timeZone } = props;
  const prevMonthDate = prevMonthFn(currentMonth, timeZone);
  const currentMonthDate = getMonthStartInTimeZone(TODAY, timeZone);

  return dateIsAfter(prevMonthDate, currentMonthDate) ? <PreviousMonthIcon /> : null;
};

export const isInterDayBookingStartingOnDay = (availableTimes, openingHoursForDate) => {
  return (
    timeOfDayStringTo12HourClock(availableTimes[availableTimes.length - 1].timeOfDay) !==
    timeOfDayStringTo12HourClock(openingHoursForDate[openingHoursForDate.length - 1].endTime)
  );
};

// it's an inter-day booking ending on this day (or the user is booking a return for an intra-day booking)
export const isFirstSlotOfDayTaken = (availableTimes, openingHoursForDate) => {
  return (
    timeOfDayStringTo12HourClock(availableTimes[0].timeOfDay) !==
    timeOfDayStringTo12HourClock(openingHoursForDate[0].startTime)
  );
};

// inter-day booking starting or ending on this day or the user is booking a return for an intra-day booking
export const isFirstOrLastSlotOfDayTaken = (availableTimes, openingHoursForDate) => {
  return (
    isInterDayBookingStartingOnDay(availableTimes, openingHoursForDate) ||
    isFirstSlotOfDayTaken(availableTimes, openingHoursForDate)
  );
};

export const tooltipText = (
  pickUpOrReturn,
  date,
  availableTimes,
  openingHours,
  timeZone,
  timeSlotsOnSelectedDate,
  isChangingBooking,
  minDuration,
  startTime
) => {
  const hours = openingHoursForDate(date, timeZone, openingHours);

  if (!hours) {
    return (
      <div>
        This trailer is not available for {pickUpOrReturn} on this day. Please check its pick-up and
        return hours.
      </div>
    );
  }

  const minDurationLabel = config.custom.minDurationOptions.find(
    option => option.hours === parseInt(minDuration)
  )?.label;

  if (availableTimes.length === 0) {
    const startTimeAsMoment = startTime ? moment(timestampToDate(startTime)) : null;

    if (startTimeAsMoment && !isChangingBooking) {
      const minReturnTime = startTimeAsMoment.add(minDuration, 'hours');
      if (minDuration > 24 && minReturnTime.isAfter(moment(date), 'day')) {
        return <div>This trailer must be hired for at least {minDurationLabel}.</div>;
      }
    }
    return isToday(date, timeZone) ? (
      <div>It's too late to {pickUpOrReturn} this trailer today. Please choose another date.</div>
    ) : minDuration > 24 && !isChangingBooking ? (
      // FIXME hacky cowboy solution to show a semi-helpful tooltip on the return date when an impossible pick-up date/time
      // has been selected. it would be better if we hid the available pick-up times or disallowed the date
      // being selected at all, i.e. modify getAvailableStartTimes to consider minDuration and pull in following
      // timeslots so we can check the end dates/times that would be available based on start dates/times.
      // We decided the hacky solution is OK for now because we don't want a lot of trailers with minimum hire durations,
      // so we're ok with a suboptimal UX for the few that exist, and we know we have more work to do in the timeslots/dates
      // area when we a) pull in Sharetribe's changes with React 17 and the new datepicker + b) implement date-filtered search.
      <div>
        The trailer must be hired for at least {minDurationLabel} and it is already booked on this
        day. Please adjust the pick-up or choose a different trailer.
      </div>
    ) : (
      <div>The trailer is already booked on this day.</div>
    );
  }

  const gotOpeningHoursForDate = getOpeningHoursForDate(date, timeZone, openingHours);
  if (availableTimes.length !== expectedNumberOfAvailableTimes(gotOpeningHoursForDate)) {
    if (
      pickUpOrReturn === 'return' &&
      isInterDayBookingStartingOnDay(availableTimes, gotOpeningHoursForDate)
    ) {
      return (
        <div>
          This trailer needs to be returned by{' '}
          <span className={css.noWrapTime}>
            {timeOfDayStringTo12HourClock(availableTimes[availableTimes.length - 1].timeOfDay)}
          </span>{' '}
          on this day
          {isChangingBooking ? '' : " as there's another booking"}. Please choose from the available
          return times.
        </div>
      );
    }

    if (
      pickUpOrReturn === 'pick-up' &&
      timeSlotsOnSelectedDate.length === 1 &&
      isInterDayBookingStartingOnDay(availableTimes, gotOpeningHoursForDate)
    ) {
      // one inter-day booking that starts on this day
      return (
        <div>
          This trailer will need to be returned by{' '}
          <span className={css.noWrapTime}>
            {add30MinsToTimeOfDayString(availableTimes[availableTimes.length - 1].timeOfDay)}
          </span>{' '}
          on this day as there's another booking. Please choose from the available pick-up times.
        </div>
      );
    }

    if (
      pickUpOrReturn === 'pick-up' &&
      ((timeSlotsOnSelectedDate.length === 1 &&
        isFirstSlotOfDayTaken(availableTimes, gotOpeningHoursForDate)) ||
        timeSlotsOnSelectedDate.length > 1) &&
      !isToday(date, timeZone)
    ) {
      // one inter-day booking that ends on this day OR
      // one intra-day booking OR
      // two inter-day bookings (one ending and one starting on this day ) OR
      // multiple intra-day bookings OR
      // a combination of these
      return (
        <div>
          This trailer has limited availability on this day. Please choose from the available
          pick-up times.
        </div>
      );
    }

    // today
    return <div>Choose from the available {pickUpOrReturn} times</div>;
  } else {
    // a day with no other bookings
    return (
      <div>
        Choose within {pickUpOrReturn} hours
        <br />
        {hours}
      </div>
    );
  }
};

/////////////////////////////////////
// FieldDateAndTimeInput component //
/////////////////////////////////////
class FieldDateAndTimeInput extends Component {
  constructor(props) {
    super(props);

    this.state = {
      currentMonth: getMonthStartInTimeZone(TODAY, props.timeZone),
      currentMonthOnEndDateInput: getMonthStartInTimeZone(TODAY, props.timeZone),
    };

    this.fetchMonthData = this.fetchMonthData.bind(this);
    this.onMonthClick = this.onMonthClick.bind(this);
    this.onBookingStartDateChange = this.onBookingStartDateChange.bind(this);
    this.onBookingStartTimeChange = this.onBookingStartTimeChange.bind(this);
    this.onBookingEndDateChange = this.onBookingEndDateChange.bind(this);
    this.isOutsideRange = this.isEndDayOutsideRange.bind(this);
  }

  fetchMonthData(date) {
    const { listingId, timeZone, onFetchTimeSlots } = this.props;
    const endOfRangeDate = endOfRange(TODAY, timeZone);

    // Don't fetch timeSlots for past months or too far in the future
    if (isInRange(date, TODAY, endOfRangeDate)) {
      // Use "today", if the first day of given month is in the past
      const start = dateIsAfter(TODAY, date) ? TODAY : date;

      // Use endOfRangeDate, if the first day of the next month is too far in the future
      const nextMonthDate = nextMonthFn(date, timeZone);
      const end = dateIsAfter(nextMonthDate, endOfRangeDate)
        ? resetToStartOfDay(endOfRangeDate, timeZone, 0)
        : nextMonthDate;

      // Fetch time slots for given time range
      onFetchTimeSlots(listingId, start, end, timeZone);
    }
  }

  onMonthClick(monthFn, stateKey = 'currentMonth') {
    const { onMonthChanged, timeZone } = this.props;

    this.setState(
      prevState => ({ [stateKey]: monthFn(prevState[stateKey], timeZone) }),
      () => {
        const currentMonth = this.state[stateKey];
        // Callback function after month has been updated.
        // react-dates component has next and previous months ready (but inivisible).
        // we try to populate those invisible months before user advances there.
        this.fetchMonthData(monthFn(currentMonth, timeZone));

        // If previous fetch for month data failed, try again.
        const monthId = monthIdStringInTimeZone(currentMonth, timeZone);
        const currentMonthData = this.props.monthlyTimeSlots[monthId];

        if (currentMonthData && currentMonthData.fetchTimeSlotsError) {
          this.fetchMonthData(currentMonth, timeZone);
        }

        // Call onMonthChanged function if it has been passed in among props.
        if (onMonthChanged) {
          onMonthChanged(monthId);
        }
      }
    );
  }

  onBookingStartDateChange = value => {
    const {
      monthlyTimeSlots,
      timeZone,
      intl,
      form,
      openingHours,
      leadTime,
      paidForQuantity,
      minDuration,
    } = this.props;
    if (!value || !value.date) {
      form.batch(() => {
        form.change('bookingStartTime', null);
        form.change('bookingEndDate', { date: null });
        form.change('bookingEndTime', null);
      });
      // Reset the currentMonth too if bookingStartDate is cleared
      this.setState({
        currentMonth: getMonthStartInTimeZone(TODAY, timeZone),
        currentMonthOnEndDateInput: getMonthStartInTimeZone(TODAY, timeZone),
      });

      return;
    }

    // This callback function (onBookingStartDateChange) is called from react-dates component.
    // It gets raw value as a param - browser's local time instead of time in listing's timezone.
    const startDate = timeOfDayFromLocalToTimeZone(value.date, timeZone);
    const timeSlots = getMonthlyTimeSlots(monthlyTimeSlots, this.state.currentMonth, timeZone);
    const timeSlotsOnSelectedDate = getTimeSlots(timeSlots, startDate, timeZone);

    const { endDate } = getAllTimeValues(
      intl,
      timeZone,
      timeSlotsOnSelectedDate,
      startDate,
      undefined,
      undefined,
      openingHours,
      leadTime,
      paidForQuantity,
      minDuration
    );

    this.setState({
      currentMonth: getMonthStartInTimeZone(startDate, timeZone),
      currentMonthOnEndDateInput: getMonthStartInTimeZone(endDate, timeZone),
    });

    form.batch(() => {
      form.change('bookingStartTime', null);
      form.change('bookingEndDate', { date: null });
      form.change('bookingEndTime', null);
    });
  };

  onBookingStartTimeChange = value => {
    const { form } = this.props;

    form.change('bookingEndTime', null);
  };

  onBookingEndDateChange = value => {
    const { form } = this.props;

    form.change('bookingEndTime', null);
  };

  isEndDayOutsideRange(day, bookingStartDate, selectedTimeSlot, timeZone, paidForQuantity) {
    // const localizedDay = timeOfDayFromLocalToTimeZone(day, timeZone);
    // const startDate = resetToStartOfDay(bookingStartDate, timeZone);
    // Hard-coded end of continuous time-slot
    // const endDate = resetToStartOfDay(new Date(new Date('2020-04-15T21:00:00.000Z') - 1), timeZone, 1);

    // return !(dateIsAfter(localizedDay, startDate) && dateIsAfter(endDate, localizedDay))
    if (!selectedTimeSlot) {
      return true;
    }

    // 'day' is pointing to browser's local time-zone (react-dates gives these).
    // However, bookingStartDate and selectedTimeSlot refer to times in listing's timeZone.
    const localizedDay = timeOfDayFromLocalToTimeZone(day, timeZone);
    // Given day (endDate) should be after the start of the day of selected booking start date.
    const startDate = resetToStartOfDay(bookingStartDate, timeZone);

    // If "day" is before bookingStartDate, it's outside range
    if (!dateIsAfter(localizedDay, startDate)) {
      return true;
    }

    // If "day" is beyond the paid-for quantity (when changing a booking), it's outside range
    if (
      !!paidForQuantity &&
      !isEndDateWithinPaidForRange(localizedDay, bookingStartDate, timeZone, paidForQuantity)
    ) {
      return true;
    }

    // 00:00 would return wrong day as the end date.
    // Removing 1 millisecond, solves the exclusivity issue.
    const inclusiveEnd = new Date(selectedTimeSlot.attributes.end.getTime() - 1);

    const hasNextMonthBoundary = isNextMonth(localizedDay, inclusiveEnd, timeZone);

    if (!hasNextMonthBoundary) {
      // Given day (localizedDay) should be before the "next" day of selected timeSlots end.
      const endDate = resetToStartOfDay(inclusiveEnd, timeZone, 1);

      // timeSlot's end should be after the current "day"
      return !dateIsAfter(endDate, localizedDay);
    } else {
      const { monthlyTimeSlots } = this.props;

      // Recursive fn
      const isMonthOutsideRange = (month, targetDay, monthlyTimeSlots, timeZone) => {
        const ts = getMonthlyTimeSlots(monthlyTimeSlots, month, timeZone);

        // If there's no timeSlots, the day is outside of range.
        if (ts.length === 0) {
          return true;
        }

        const firstTimeSlot = ts[0];
        const isContinuousTimeSlot = firstTimeSlot.attributes.start.getTime() === month.getTime();
        // If the next timeSlot is not continuous, the day is outside of range.
        if (!isContinuousTimeSlot) {
          return true;
        }

        const targetMonth = getMonthStartInTimeZone(targetDay, timeZone); // this.state.currentMonthOnEndDateInput;
        const isTargetMonth = isSameMonth(month, targetMonth, timeZone);

        if (isTargetMonth) {
          const tsEnd = firstTimeSlot.attributes.end;

          // 00:00 would return wrong day as the end date.
          // Removing 1 millisecond, solves the exclusivity issue.
          const inclusiveEnd = new Date(tsEnd.getTime() - 1);

          // Given day (targetDay) should be before the "next" day of selected timeSlots end.
          const endDate = resetToStartOfDay(inclusiveEnd, timeZone, 1);

          return !dateIsAfter(endDate, targetDay);
        } else {
          const n = getMonthStartInTimeZone(targetDay, timeZone);
          return isMonthOutsideRange(n, targetDay, monthlyTimeSlots, timeZone);
        }
      };

      const nextMonth = getMonthStartInTimeZone(selectedTimeSlot.attributes.end, timeZone);

      return isMonthOutsideRange(nextMonth, localizedDay, monthlyTimeSlots, timeZone);
    }
  }

  render() {
    const {
      rootClassName,
      className,
      formId,
      startDateInputProps,
      endDateInputProps,
      startTimePlaceholder,
      endTimePlaceholder,
      values,
      monthlyTimeSlots,
      timeZone,
      openingHours,
      intl,
      paidForQuantity,
      isStartDayOutsideRange,
      leadTime,
      minDuration,
    } = this.props;

    const classes = classNames(rootClassName || css.root, className);

    const bookingStartDate =
      values.bookingStartDate && values.bookingStartDate.date ? values.bookingStartDate.date : null;

    const bookingStartTime = values.bookingStartTime ? values.bookingStartTime : null;
    const bookingEndDate =
      values.bookingEndDate && values.bookingEndDate.date ? values.bookingEndDate.date : null;

    const startTimeDisabled = !bookingStartDate;
    const endDateDisabled = !bookingStartDate || !bookingStartTime;
    const endTimeDisabled = !bookingStartDate || !bookingStartTime || !bookingEndDate;

    const timeSlotsOnSelectedMonth = stateKey =>
      getMonthlyTimeSlots(monthlyTimeSlots, this.state[stateKey], timeZone);

    const timeSlotsOnSelectedStartDate = getTimeSlots(
      getMonthlyTimeSlots(
        monthlyTimeSlots,
        getMonthStartInTimeZone(bookingStartDate || this.state.currentMonth, timeZone),
        timeZone
      ),
      bookingStartDate,
      timeZone
    );

    const availableStartTimes = getAvailableStartTimes(
      intl,
      timeZone,
      bookingStartDate,
      timeSlotsOnSelectedStartDate,
      openingHours,
      leadTime,
      paidForQuantity
    );

    const firstAvailableStartTime =
      availableStartTimes.length > 0 && availableStartTimes[0] && availableStartTimes[0].timestamp
        ? availableStartTimes[0].timestamp
        : null;

    // TODO add timeslots from future months too.
    const timeSlotsOnSelectedDate = timeSlotsOnSelectedStartDate;

    const { startTime, endDate, selectedTimeSlot } = getAllTimeValues(
      intl,
      timeZone,
      timeSlotsOnSelectedDate,
      bookingStartDate,
      bookingStartTime || firstAvailableStartTime,
      bookingEndDate || bookingStartDate,
      openingHours,
      leadTime,
      paidForQuantity,
      minDuration
    );

    const availableEndTimes = getAvailableEndTimes(
      intl,
      timeZone,
      bookingStartTime || startTime,
      bookingEndDate || endDate,
      selectedTimeSlot,
      openingHours,
      paidForQuantity,
      minDuration
    );

    const isDayBlocked = ts => {
      return ts
        ? day => {
            const f = ts.find(timeSlot =>
              isDayMomentInsideRange(
                day,
                timeSlot.attributes.start,
                timeSlot.attributes.end,
                timeZone
              )
            );
            return !f;
          }
        : () => false;
    };

    const defaultPlaceholderTime = localizeAndFormatTime(
      intl,
      timeZone,
      findNextBoundary(timeZone, TODAY)
    );

    const tooltip = (pickUpOrReturn, date, availableTimes, isChangingBooking) => {
      if (hasOpeningHoursForDate(date, timeZone, openingHours) && availableTimes.length > 0) {
        return (
          <div className={css.openingHoursTooltipWrapper}>
            <div className={css.openingHoursTooltipWithHours}>
              {tooltipText(
                pickUpOrReturn,
                date,
                availableTimes,
                openingHours,
                timeZone,
                timeSlotsOnSelectedDate,
                isChangingBooking,
                minDuration,
                startTime
              )}
            </div>
            <div className={css.arrowDown}></div>
          </div>
        );
      }

      return (
        <div className={css.openingHoursTooltipWrapper}>
          <div className={css.arrowUp}></div>
          <div className={css.openingHoursTooltipWithoutHours}>
            {tooltipText(
              pickUpOrReturn,
              date,
              availableTimes,
              openingHours,
              timeZone,
              timeSlotsOnSelectedDate,
              isChangingBooking,
              minDuration,
              startTime
            )}
          </div>
        </div>
      );
    };

    return (
      <div className={classes}>
        <div className={css.formRow}>
          <div className={classNames(css.field)}>
            <FieldDateInput
              className={css.fieldDateInput}
              name="bookingStartDate"
              id={formId ? `${formId}.bookingStartDate` : 'bookingStartDate'}
              label={startDateInputProps.label}
              placeholderText={startDateInputProps.placeholderText}
              format={v =>
                v && v.date ? { date: timeOfDayFromTimeZoneToLocal(v.date, timeZone) } : v
              }
              parse={v =>
                v && v.date ? { date: timeOfDayFromLocalToTimeZone(v.date, timeZone) } : v
              }
              isDayBlocked={isDayBlocked(timeSlotsOnSelectedMonth('currentMonth'))}
              isOutsideRange={isStartDayOutsideRange}
              onChange={this.onBookingStartDateChange}
              onPrevMonthClick={() => this.onMonthClick(prevMonthFn)}
              onNextMonthClick={() => this.onMonthClick(nextMonthFn)}
              navNext={<Next currentMonth={this.state.currentMonth} timeZone={timeZone} />}
              navPrev={<Prev currentMonth={this.state.currentMonth} timeZone={timeZone} />}
              useMobileMargins
              showErrorMessage={false}
              validate={bookingDateRequired('Required')}
              onClose={event =>
                this.setState({
                  currentMonth: getMonthStartInTimeZone(event?.date ?? TODAY, this.props.timeZone),
                })
              }
            />
          </div>
          {bookingStartDate && !values.bookingStartTime
            ? tooltip('pick-up', bookingStartDate, availableStartTimes)
            : null}
          <div className={css.field}>
            <FieldSelect
              name="bookingStartTime"
              id={formId ? `${formId}.bookingStartTime` : 'bookingStartTime'}
              className={bookingStartDate ? css.fieldSelect : css.fieldSelectDisabled}
              selectClassName={bookingStartDate ? css.select : css.selectDisabled}
              // label={startTimeLabel}
              disabled={startTimeDisabled}
              onChange={this.onBookingStartTimeChange}
            >
              {bookingStartDate &&
              !values.bookingStartTime &&
              hasOpeningHoursForDate(bookingStartDate, timeZone, openingHours) ? (
                <option>Choose from available times</option>
              ) : null}
              {bookingStartDate ? (
                availableStartTimes.map(p => (
                  <option key={p.timeOfDay} value={p.timestamp}>
                    {timeOfDayStringTo12HourClock(p.timeOfDay)}
                  </option>
                ))
              ) : (
                <option>
                  {timeOfDayStringTo12HourClock(startTimePlaceholder ?? defaultPlaceholderTime)}
                </option>
              )}
            </FieldSelect>
          </div>
        </div>
        <div className={css.formRow}>
          <div className={classNames(css.field)}>
            <FieldDateInput
              {...endDateInputProps}
              name="bookingEndDate"
              id={formId ? `${formId}.bookingEndDate` : 'bookingEndDate'}
              className={css.fieldDateInput}
              label={endDateInputProps.label}
              placeholderText={endDateInputProps.placeholderText}
              format={v =>
                v && v.date ? { date: timeOfDayFromTimeZoneToLocal(v.date, timeZone) } : v
              }
              parse={v =>
                v && v.date ? { date: timeOfDayFromLocalToTimeZone(v.date, timeZone) } : v
              }
              onChange={this.onBookingEndDateChange}
              onPrevMonthClick={params => {
                console.log('asdf', params.toISOString());
                this.onMonthClick(prevMonthFn, 'currentMonthOnEndDateInput');
              }}
              onNextMonthClick={() => this.onMonthClick(nextMonthFn, 'currentMonthOnEndDateInput')}
              onClose={() =>
                this.setState({
                  currentMonthOnEndDateInput: getMonthStartInTimeZone(
                    endDate || bookingStartDate,
                    timeZone
                  ),
                })
              }
              navNext={
                <Next currentMonth={this.state.currentMonthOnEndDateInput} timeZone={timeZone} />
              }
              navPrev={
                <Prev currentMonth={this.state.currentMonthOnEndDateInput} timeZone={timeZone} />
              }
              isOutsideRange={day => {
                const isOut = this.isEndDayOutsideRange(
                  day,
                  bookingStartDate,
                  selectedTimeSlot,
                  timeZone,
                  paidForQuantity
                );
                return isOut;
              }}
              useMobileMargins
              showErrorMessage={false}
              validate={bookingDateRequired('Required')}
              disabled={endDateDisabled}
              showLabelAsDisabled={endDateDisabled}
              initialVisibleMonth={() => moment(this.state.currentMonthOnEndDateInput).tz(timeZone)}
            />
          </div>

          {/*<div className={bookingStartDate ? css.lineBetween : css.lineBetweenDisabled}>-</div>*/}

          {bookingEndDate && !values.bookingEndTime
            ? tooltip('return', bookingEndDate, availableEndTimes, !!paidForQuantity)
            : null}

          <div className={css.field}>
            <FieldSelect
              name="bookingEndTime"
              id={formId ? `${formId}.bookingEndTime` : 'bookingEndTime'}
              className={bookingStartDate ? css.fieldSelect : css.fieldSelectDisabled}
              selectClassName={bookingStartDate ? css.select : css.selectDisabled}
              disabled={endTimeDisabled}
              validate={validators.required(' ')}
            >
              {bookingStartDate &&
              bookingEndDate &&
              (bookingStartTime || startTime) &&
              !values.bookingEndTime &&
              hasOpeningHoursForDate(bookingEndDate, timeZone, openingHours) ? (
                <option>Choose from available times</option>
              ) : null}
              {bookingStartDate && (bookingStartTime || startTime) ? (
                availableEndTimes.map(p => (
                  <option key={p.timeOfDay === '00:00' ? '24:00' : p.timeOfDay} value={p.timestamp}>
                    {timeOfDayStringTo12HourClock(p.timeOfDay)}
                  </option>
                ))
              ) : (
                <option>
                  {timeOfDayStringTo12HourClock(endTimePlaceholder ?? defaultPlaceholderTime)}
                </option>
              )}
            </FieldSelect>
          </div>
        </div>
      </div>
    );
  }
}

FieldDateAndTimeInput.defaultProps = {
  rootClassName: null,
  className: null,
  startDateInputProps: null,
  endDateInputProps: null,
  startTimeInputProps: null,
  endTimeInputProps: null,
  listingId: null,
  monthlyTimeSlots: null,
  timeZone: null,
  isStartDayOutsideRange: () => false,
  leadTime: 0,
  minDuration: 0,
};

FieldDateAndTimeInput.propTypes = {
  rootClassName: string,
  className: string,
  formId: string,
  bookingStartLabel: object,
  startDateInputProps: object,
  endDateInputProps: object,
  startTimeInputProps: object,
  endTimeInputProps: object,
  form: object.isRequired,
  values: object.isRequired,
  listingId: propTypes.uuid,
  monthlyTimeSlots: object,
  onFetchTimeSlots: func.isRequired,
  timeZone: string,
  paidForQuantity: number,
  startTimePlaceholder: string,
  endTimePlaceholder: string,
  isStartDayOutsideRange: func,
  leadTime: number,
  minDuration: number,

  // from injectIntl
  intl: intlShape.isRequired,
};

export default FieldDateAndTimeInput;
