import React from 'react';
import loadable from '@loadable/component';
import getPageDataLoadingAPI from './containers/pageDataLoadingAPI';
import { NotFoundPage } from './containers';

// routeConfiguration needs to initialize containers first
// Otherwise, components will import form container eventually and
// at that point css bundling / imports will happen in wrong order.
import { NamedRedirect } from './components';

const pageDataLoadingAPI = getPageDataLoadingAPI();

const AboutPage = loadable(() => import(/* webpackChunkName: "AboutPage" */ './containers/AboutPage/AboutPage'));
const AuthenticationPage = loadable(() => import(/* webpackChunkName: "AuthenticationPage" */ './containers/AuthenticationPage/AuthenticationPage'));
const CheckoutPage = loadable(() => import(/* webpackChunkName: "CheckoutPage" */ './containers/CheckoutPage/CheckoutPage'));
const ContactDetailsPage = loadable(() => import(/* webpackChunkName: "ContactDetailsPage" */ './containers/ContactDetailsPage/ContactDetailsPage'));
const EditListingPage = loadable(() => import(/* webpackChunkName: "EditListingPage" */ './containers/EditListingPage/EditListingPage'));
const EmailVerificationPage = loadable(() => import(/* webpackChunkName: "EmailVerificationPage" */ './containers/EmailVerificationPage/EmailVerificationPage'));
const InboxPage = loadable(() => import(/* webpackChunkName: "InboxPage" */ './containers/InboxPage/InboxPage'));
const LandingPage = loadable(() => import(/* webpackChunkName: "LandingPage" */ './containers/LandingPage/LandingPage'));
const ListingPage = loadable(() => import(/* webpackChunkName: "ListingPage" */ /* webpackPrefetch: true */ './containers/ListingPage/ListingPage'));
const PasswordChangePage = loadable(() => import(/* webpackChunkName: "PasswordChangePage" */ './containers/PasswordChangePage/PasswordChangePage'));
const PasswordRecoveryPage = loadable(() => import(/* webpackChunkName: "PasswordRecoveryPage" */ './containers/PasswordRecoveryPage/PasswordRecoveryPage'));
const PasswordResetPage = loadable(() => import(/* webpackChunkName: "PasswordResetPage" */ './containers/PasswordResetPage/PasswordResetPage'));
const PaymentMethodsPage = loadable(() => import(/* webpackChunkName: "PaymentMethodsPage" */ './containers/PaymentMethodsPage/PaymentMethodsPage'));
const PrivacyPolicyPage = loadable(() => import(/* webpackChunkName: "PrivacyPolicyPage" */ './containers/PrivacyPolicyPage/PrivacyPolicyPage'));
const ProfilePage = loadable(() => import(/* webpackChunkName: "ProfilePage" */ './containers/ProfilePage/ProfilePage'));
const ProfileSettingsPage = loadable(() => import(/* webpackChunkName: "ProfileSettingsPage" */ './containers/ProfileSettingsPage/ProfileSettingsPage'));
const SearchPage = loadable(() => import(/* webpackChunkName: "SearchPage" */ /* webpackPrefetch: true */  './containers/SearchPage/SearchPage'));
const StripePayoutPage = loadable(() => import(/* webpackChunkName: "StripePayoutPage" */ './containers/StripePayoutPage/StripePayoutPage'));
const TermsOfServicePage = loadable(() => import(/* webpackChunkName: "TermsOfServicePage" */ './containers/TermsOfServicePage/TermsOfServicePage'));
const TransactionPage = loadable(() => import(/* webpackChunkName: "TransactionPage" */ './containers/TransactionPage/TransactionPage'));

const DashboardOverviewPage = loadable(() => import(/* webpackChunkName: "DashboardOverviewPage" */ './containers/DashboardOverviewPage/DashboardOverviewPage'));
const EarningsPage = loadable(() => import(/* webpackChunkName: "EarningsPage" */ './containers/EarningsPage/EarningsPage'));
const FAQPage = loadable(() => import(/* webpackChunkName: "FAQPage" */ './containers/FAQPage/FAQPage'));
const FlyerPage = loadable(() => import(/* webpackChunkName: "FlyerPage" */ './containers/FlyerPage/FlyerPage'));
const FlyersPage = loadable(() => import(/* webpackChunkName: "FlyersPage" */ './containers/FlyersPage/FlyersPage'));
const ForBusinessPage = loadable(() => import(/* webpackChunkName: "ForBusinessPage" */ './containers/ForBusinessPage/ForBusinessPage'));
const ForHirersPage = loadable(() => import(/* webpackChunkName: "ForHirersPage" */ './containers/ForHirersPage/ForHirersPage'));
const ForOwnersPage = loadable(() => import(/* webpackChunkName: "ForOwnersPage" */ './containers/ForOwnersPage/ForOwnersPage'));
const ManageListingsPage = loadable(() => import(/* webpackChunkName: "ManageListingsPage" */ './containers/ManageListingsPage/ManageListingsPage'));
const MusteringPage = loadable(() => import(/* webpackChunkName: "MusteringPage" */ './containers/MusteringPage/MusteringPage'));
const OwnerStatusPage = loadable(() => import(/* webpackChunkName: "OwnerStatusPage" */ './containers/OwnerStatusPage/OwnerStatusPage'));
const OwnerTermsPage = loadable(() => import(/* webpackChunkName: "OwnerTermsPage" */ './containers/OwnerTermsPage/OwnerTermsPage'));
const PremiumListingPage = loadable(() => import(/* webpackChunkName: "PremiumListingPage" */ './containers/PremiumListingPage/PremiumListingPage'));
const RentalTermsPage = loadable(() => import(/* webpackChunkName: "RentalTermsPage" */ './containers/RentalTermsPage/RentalTermsPage'));
const StickersPage = loadable(() => import(/* webpackChunkName: "StickersPage" */ './containers/StickersPage/StickersPage'));
const TipsPage = loadable(() => import(/* webpackChunkName: "TipsPage" */ './containers/TipsPage/TipsPage'));
const TrackersPage = loadable(() => import(/* webpackChunkName: "TrackersPage" */ './containers/TrackersPage/TrackersPage'));
const TrailerCarePage = loadable(() => import(/* webpackChunkName: "TrailerCarePage" */ './containers/TrailerCarePage/TrailerCarePage'));

// trailer info & help pages
const SafeHiringPage = loadable(() => import(/* webpackChunkName: "SafeHiringPage" */ './containers/SafeHiringPage/SafeHiringPage'));
const PickupPage = loadable(() => import(/* webpackChunkName: "PickupPage" */ './containers/PickupPage/PickupPage'));
const PickupPageForOwner = loadable(() => import(/* webpackChunkName: "PickupPageForOwner" */ './containers/PickupPageForOwner/PickupPageForOwner'));
const ExplainWeightsPage = loadable(() => import(/* webpackChunkName: "ExplainWeightsPage" */ './containers/ExplainWeightsPage/ExplainWeightsPage'));
const ExplainElectricBrakesPage = loadable(() => import(/* webpackChunkName: "ExplainElectricBrakesPage" */ './containers/ExplainElectricBrakesPage/ExplainElectricBrakesPage'));
const ExplainCancellationPage = loadable(() => import(/* webpackChunkName: "ExplainCancellationPage" */ './containers/ExplainCancellationPage/ExplainCancellationPage'));
const ExplainPaymentPage = loadable(() => import(/* webpackChunkName: "ExplainPaymentPage" */ './containers/ExplainPaymentPage/ExplainPaymentPage'));


// Styleguide helps you to review current components and develop new ones
const StyleguidePage = loadable(() => import(/* webpackChunkName: "StyleguidePage" */ './containers/StyleguidePage/StyleguidePage'));

export const ACCOUNT_SETTINGS_PAGES = [
  'ContactDetailsPage',
  'PasswordChangePage',
  'StripePayoutPage',
  'PaymentMethodsPage',
];

export const DASHBOARD_PAGES = ['EarningsPage', 'OwnerStatusPage', 'TipsPage', 'DashboardOverviewPage', 'InboxPage', 'TrailerCarePage', 'StickersPage', 'FlyersPage', 'TrackersPage'];

// https://en.wikipedia.org/wiki/Universally_unique_identifier#Nil_UUID
const draftId = '00000000-0000-0000-0000-000000000000';
const draftSlug = 'draft';

const RedirectToLandingPage = () => <NamedRedirect name="LandingPage" />;

// NOTE: Most server-side endpoints are prefixed with /api. Requests to those
// endpoints are indended to be handled in the server instead of the browser and
// they will not render the application. So remember to avoid routes starting
// with /api and if you encounter clashing routes see server/index.js if there's
// a conflicting route defined there.

// Our routes are exact by default.
// See behaviour from Routes.js where Route is created.
const routeConfiguration = () => {
  return [
    {
      path: '/',
      name: 'LandingPage',
      component: LandingPage,
    },
    {
      path: '/sharing',
      name: 'ForOwnersPage',
      component: ForOwnersPage,
    },
    {
      path: '/share',
      name: 'ForOwnersPageRedirect',
      component: () => <NamedRedirect name="ForOwnersPage" />,
    },
    {
      path: '/hiring',
      name: 'ForHirersPage',
      component: ForHirersPage,
    },
    {
      path: '/the',
      name: 'MusteringPageRedirect',
      component: MusteringPage,
    },
    {
      path: '/muster',
      name: 'MusteringPage',
      component: MusteringPage,
    },
    {
      path: '/refer',
      name: 'MusteringPageRedirect',
      component: () => <NamedRedirect name="MusteringPage" />,
    },
    {
      path: '/business',
      name: 'ForBusinessPage',
      component: ForBusinessPage,
    },
    {
      path: '/hire',
      name: 'ForHirersPageRedirect',
      component: () => <NamedRedirect name="ForHirersPage" />,
    },
    {
      path: '/about',
      name: 'AboutPage',
      component: AboutPage,
    },
    {
      path: '/faq',
      name: 'FAQPage',
      component: FAQPage,
    },
    {
      path: '/help',
      name: 'HelpPage',
      component: FAQPage,
    },
    {
      path: '/safe-hiring-guide',
      name: 'SafeHiringPage',
      component: SafeHiringPage,
    },
    {
      path: '/pickup',
      name: 'PickupPage',
      component: PickupPage,
    },
    {
      path: '/owner-operations-guide',
      name: 'PickupPageForOwner',
      component: PickupPageForOwner,
    },
    {
      path: '/premium-listing',
      name: 'PremiumListingPage',
      component: PremiumListingPage,
    },
    {
      path: '/s',
      name: 'SearchPage',
      component: SearchPage,
      loadData: pageDataLoadingAPI.SearchPage.loadData,
    },
    {
      path: '/l',
      name: 'ListingBasePage',
      component: RedirectToLandingPage,
    },
    {
      path: '/l/:slug/:id',
      name: 'ListingPage',
      component: ListingPage,
      loadData: pageDataLoadingAPI.ListingPage.loadData,
    },
    {
      path: '/l/:slug/:id/checkout',
      name: 'CheckoutPage',
      auth: true,
      component: CheckoutPage,
      setInitialValues: pageDataLoadingAPI.CheckoutPage.setInitialValues,
    },
    {
      path: '/l/:slug/:id/:variant',
      name: 'ListingPageVariant',
      auth: true,
      authPage: 'LoginPage',
      component: ListingPage,
      loadData: pageDataLoadingAPI.ListingPage.loadData,
    },
    {
      path: '/l/new',
      name: 'NewListingPage',
      auth: true,
      component: () => (
        <NamedRedirect
          name="EditListingPage"
          params={{ slug: draftSlug, id: draftId, type: 'new', tab: 'overview' }}
        />
      ),
    },
    {
      path: '/l/:slug/:id/:type/:tab',
      name: 'EditListingPage',
      auth: true,
      component: EditListingPage,
      loadData: pageDataLoadingAPI.EditListingPage.loadData,
    },
    {
      path: '/l/:slug/:id/:type/:tab/:returnURLType',
      name: 'EditListingStripeOnboardingPage',
      auth: true,
      component: EditListingPage,
      loadData: pageDataLoadingAPI.EditListingPage.loadData,
    },

    // Canonical path should be after the `/l/new` path since they
    // conflict and `new` is not a valid listing UUID.
    {
      path: '/l/:id',
      name: 'ListingPageCanonical',
      component: ListingPage,
      loadData: pageDataLoadingAPI.ListingPage.loadData,
    },
    {
      path: '/u',
      name: 'ProfileBasePage',
      component: RedirectToLandingPage,
    },
    {
      path: '/u/:id',
      name: 'ProfilePage',
      component: ProfilePage,
      loadData: pageDataLoadingAPI.ProfilePage.loadData,
    },
    {
      path: '/profile-settings',
      name: 'ProfileSettingsPage',
      auth: true,
      authPage: 'LoginPage',
      component: ProfileSettingsPage,
    },

    // Note: authenticating with IdP (e.g. Facebook) expects that /login path exists
    // so that in the error case users can be redirected back to the LoginPage
    // In case you change this, remember to update the route in server/api/auth/loginWithIdp.js
    {
      path: '/login',
      name: 'LoginPage',
      component: AuthenticationPage,
      extraProps: { tab: 'login' },
    },
    {
      path: '/signup',
      name: 'SignupPage',
      component: AuthenticationPage,
      extraProps: { tab: 'signup' },
    },
    {
      path: '/fuel',
      name: 'SignupPageWithFuelPromo',
      component: AuthenticationPage,
      extraProps: { tab: 'signup', promoCode: 'FUEL' },
    },
    {
      path: '/r/crystal',
      name: 'SignupPageWithReferral',
      component: AuthenticationPage,
      extraProps: { tab: 'signup', referrerName: 'crystal' },
    },
    {
      path: '/confirm',
      name: 'ConfirmPage',
      component: AuthenticationPage,
      extraProps: { tab: 'confirm' },
    },
    {
      path: '/confirm-fuel',
      name: 'ConfirmPageWithFuelPromo',
      component: AuthenticationPage,
      extraProps: { tab: 'confirm', promoCode: 'FUEL' },
    },
    {
      path: '/r/confirm-crystal',
      name: 'ConfirmPageWithReferral',
      component: AuthenticationPage,
      extraProps: { tab: 'confirm', referrerName: 'crystal'  },
    },
    {
      path: '/recover-password',
      name: 'PasswordRecoveryPage',
      component: PasswordRecoveryPage,
    },
    {
      path: '/inbox',
      name: 'InboxBasePage',
      auth: true,
      authPage: 'LoginPage',
      component: () => <NamedRedirect name="InboxPage" params={{ tab: 'sales' }} />,
    },
    {
      path: '/inbox/:tab',
      name: 'InboxPage',
      auth: true,
      authPage: 'LoginPage',
      component: InboxPage,
      loadData: pageDataLoadingAPI.InboxPage.loadData,
    },
    {
      path: '/order/:id',
      name: 'OrderPage',
      auth: true,
      authPage: 'LoginPage',
      component: props => <NamedRedirect name="OrderDetailsPage" params={{ ...props.params }} />,
    },
    {
      path: '/order/:id/details',
      name: 'OrderDetailsPage',
      auth: true,
      authPage: 'LoginPage',
      component: TransactionPage,
      extraProps: { transactionRole: 'customer' },
      loadData: params =>
        pageDataLoadingAPI.TransactionPage.loadData({ ...params, transactionRole: 'customer' }),
      setInitialValues: pageDataLoadingAPI.TransactionPage.setInitialValues,
    },
    {
      path: '/sale/:id',
      name: 'SalePage',
      auth: true,
      authPage: 'LoginPage',
      component: props => <NamedRedirect name="SaleDetailsPage" params={{ ...props.params }} />,
    },
    {
      path: '/sale/:id/details',
      name: 'SaleDetailsPage',
      auth: true,
      authPage: 'LoginPage',
      component: TransactionPage,
      extraProps: { transactionRole: 'provider' },
      loadData: params =>
        pageDataLoadingAPI.TransactionPage.loadData({ ...params, transactionRole: 'provider' }),
    },
    {
      path: '/listings',
      name: 'ManageListingsPage',
      auth: true,
      authPage: 'LoginPage',
      component: props => <ManageListingsPage {...props} />,
      loadData: pageDataLoadingAPI.ManageListingsPage.loadData,
    },
    {
      path: '/dashboard',
      name: 'OwnerDashboardPage',
      auth: true,
      authPage: 'LoginPage',
      component: () => <NamedRedirect name="DashboardOverviewPage" />,
    },
    {
      path: '/dashboard/earnings',
      name: 'EarningsBasePage',
      auth: true,
      authPage: 'LoginPage',
      component: props => <NamedRedirect name="EarningsPage" params={{ yearType: 'financial' }} />,
    },
    {
      path: '/dashboard/earnings/:yearType',
      name: 'EarningsPage',
      auth: true,
      authPage: 'LoginPage',
      component: props => <EarningsPage {...props} />,
      loadData: pageDataLoadingAPI.EarningsPage.loadData,
    },
    {
      path: '/dashboard/status',
      name: 'OwnerStatusPage',
      auth: true,
      authPage: 'LoginPage',
      component: props => <OwnerStatusPage {...props} />,
    },
    {
      path: '/dashboard/tips',
      name: 'TipsPage',
      auth: true,
      authPage: 'LoginPage',
      component: props => <TipsPage {...props} />,
    },
    {
      path: '/dashboard/stickers',
      name: 'StickersPage',
      auth: true,
      authPage: 'LoginPage',
      component: props => <StickersPage {...props} />,
      loadData: pageDataLoadingAPI.StickersPage.loadData,
    },
    {
      path: '/dashboard/flyers',
      name: 'FlyersPage',
      auth: true,
      authPage: 'LoginPage',
      component: props => <FlyersPage {...props} />,
      loadData: pageDataLoadingAPI.FlyersPage.loadData,
    },
    {
      path: '/flyer/:id',
      name: 'FlyerPage',
      auth: false,
      component: props => <FlyerPage {...props} />,
      loadData: pageDataLoadingAPI.FlyerPage.loadData,
    },
    {
      path: '/dashboard/trailer-care',
      name: 'TrailerCarePage',
      auth: true,
      authPage: 'LoginPage',
      component: props => <TrailerCarePage {...props} />,
    },
    {
      path: '/dashboard/trackers',
      name: 'TrackersPage',
      auth: true,
      authPage: 'LoginPage',
      component: props => <TrackersPage {...props} />,
    },
    {
      path: '/dashboard/overview',
      name: 'DashboardOverviewPage',
      auth: true,
      authPage: 'LoginPage',
      component: props => <DashboardOverviewPage {...props} />,
      loadData: pageDataLoadingAPI.DashboardOverviewPage.loadData,
    },
    {
      path: '/account',
      name: 'AccountSettingsPage',
      auth: true,
      authPage: 'LoginPage',
      component: () => <NamedRedirect name="ContactDetailsPage" />,
    },
    {
      path: '/account/personal-info',
      name: 'ContactDetailsPage',
      auth: true,
      authPage: 'LoginPage',
      component: ContactDetailsPage,
      loadData: pageDataLoadingAPI.ContactDetailsPage.loadData,
    },
    {
      path: '/account/change-password',
      name: 'PasswordChangePage',
      auth: true,
      authPage: 'LoginPage',
      component: PasswordChangePage,
    },
    {
      path: '/account/payments',
      name: 'StripePayoutPage',
      auth: true,
      authPage: 'LoginPage',
      component: StripePayoutPage,
      loadData: pageDataLoadingAPI.StripePayoutPage.loadData,
    },
    {
      path: '/account/payments/:returnURLType',
      name: 'StripePayoutOnboardingPage',
      auth: true,
      authPage: 'LoginPage',
      component: StripePayoutPage,
      loadData: pageDataLoadingAPI.StripePayoutPage.loadData,
    },
    {
      path: '/account/payment-methods',
      name: 'PaymentMethodsPage',
      auth: true,
      authPage: 'LoginPage',
      component: PaymentMethodsPage,
      loadData: pageDataLoadingAPI.PaymentMethodsPage.loadData,
    },
    {
      path: '/terms-of-service',
      name: 'TermsOfServicePage',
      component: TermsOfServicePage,
    },
    {
      path: '/privacy-policy',
      name: 'PrivacyPolicyPage',
      component: PrivacyPolicyPage,
    },
    {
      path: '/owner-terms',
      name: 'OwnerTermsPage',
      component: props => <OwnerTermsPage {...props} />,
    },
    {
      path: '/rental-terms',
      name: 'RentalTermsPage',
      component: props => <RentalTermsPage {...props} />,
    },
    {
      path: '/styleguide',
      name: 'Styleguide',
      component: StyleguidePage,
    },
    {
      path: '/styleguide/g/:group',
      name: 'StyleguideGroup',
      component: StyleguidePage,
    },
    {
      path: '/styleguide/c/:component',
      name: 'StyleguideComponent',
      component: StyleguidePage,
    },
    {
      path: '/styleguide/c/:component/:example',
      name: 'StyleguideComponentExample',
      component: StyleguidePage,
    },
    {
      path: '/styleguide/c/:component/:example/raw',
      name: 'StyleguideComponentExampleRaw',
      component: StyleguidePage,
      extraProps: { raw: true },
    },
    {
      path: '/notfound',
      name: 'NotFoundPage',
      component: props => <NotFoundPage {...props} />,
    },
    {
      path: '/help/trailer-weights',
      name: 'ExplainWeightsPage',
      component: ExplainWeightsPage,
    },
    {
      path: '/help/electric-brakes',
      name: 'ExplainElectricBrakesPage',
      component: ExplainElectricBrakesPage
      ,
    },
    {
      path: '/help/cancellation',
      name: 'ExplainCancellationPage',
      component: ExplainCancellationPage
      ,
    },
    {
      path: '/help/payment',
      name: 'ExplainPaymentPage',
      component: ExplainPaymentPage
      ,
    },

    // Do not change this path!
    //
    // The API expects that the application implements /reset-password endpoint
    {
      path: '/reset-password',
      name: 'PasswordResetPage',
      component: PasswordResetPage ,
    },

    // Do not change this path!
    //
    // The API expects that the application implements /verify-email endpoint
    {
      path: '/verify-email',
      name: 'EmailVerificationPage',
      auth: true,
      authPage: 'LoginPage',
      component: EmailVerificationPage,
      loadData: pageDataLoadingAPI.EmailVerificationPage.loadData,
    },
  ];
};

export default routeConfiguration;
