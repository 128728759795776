import React from 'react';
import { Text, View } from '@react-pdf/renderer';
import { bool } from 'prop-types';
import { intlShape } from '../../util/reactIntl';
import { propTypes } from '../../util/types';
import { styles } from '../Invoice/InvoiceStyles';
import { lineItemLiabilityReductionMaybe } from '../../util/bookingBreakdown/lineItemLiabilityReductionMaybe';
import config from '../../config';

const styleSheet = styles;

const LineItemLiabilityReductionMaybePdf = props => {
  const { transaction, isCustomer, intl } = props;
  const { label, price, detail } = lineItemLiabilityReductionMaybe(transaction, intl);

  return !!price && isCustomer && config.custom.featureToggles[config.env].liabilitySelection ? (
    <>
      <View
        style={[
          styleSheet.row,
          styleSheet.borderTop,
          styleSheet.marginAboveLarge,
          styleSheet.paddingAboveLarge,
        ]}
      >
        <Text style={[styleSheet.boldFont, styleSheet.tColumnLeft]}>{label}</Text>
        <View style={styleSheet.tColumnCentre}>
          <Text>{detail}</Text>
        </View>
        <View style={styleSheet.rightAligned}>
          <Text>{price}</Text>
        </View>
      </View>
    </>
  ) : null;
};

LineItemLiabilityReductionMaybePdf.propTypes = {
  transaction: propTypes.transaction.isRequired,
  isCustomer: bool.isRequired,
  intl: intlShape,
};

export default LineItemLiabilityReductionMaybePdf;
