import React from 'react';
import { compose } from 'redux';
import { bool, number, shape, string } from 'prop-types';
import { FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';
import { withViewport } from '../../util/contextHelpers';
import { Link } from 'react-scroll';

import css from './SectionMusteringHero.module.css';

const SectionMusteringHeroComponent = props => {
  const { rootClassName, className, isAprilPromotion } = props;

  const classes = classNames(rootClassName || css.root, className);

  return (
    <div className={classes}>
      <div className={css.heroContent}>
        <div className={css.heroMainTitleDiv}>
          <h1 className={css.heroMainTitle}>
            {isAprilPromotion ? (
              <FormattedMessage id="SectionMusteringHero.titleApril" />
            ) : (
              <FormattedMessage id="SectionMusteringHero.title" />
            )}
          </h1>
          <h2 className={css.heroSubTitle}>
            {isAprilPromotion ? (
              <FormattedMessage id="SectionMusteringHero.subTitleApril" />
            ) : (
              <FormattedMessage id="SectionMusteringHero.subTitle" />
            )}
          </h2>
        </div>
      </div>
    </div>
  );
};

SectionMusteringHeroComponent.defaultProps = { rootClassName: null, className: null };

SectionMusteringHeroComponent.propTypes = {
  rootClassName: string,
  className: string,
  isAprilPromotion: bool,

  // from withViewport
  viewport: shape({
    width: number.isRequired,
    height: number.isRequired,
  }).isRequired,
};

const SectionMusteringHero = compose(withViewport)(SectionMusteringHeroComponent);

export default SectionMusteringHero;
