import React from 'react';
import { bool } from 'prop-types';
import { intlShape } from '../../util/reactIntl';
import { propTypes } from '../../util/types';
import { lineItemTotalPrice } from '../../util/bookingBreakdown/lineItemTotalPrice';

import css from './BookingBreakdown.module.css';

const LineItemUnitPrice = props => {
  const { transaction, isProvider, intl } = props;

  const { label, price } = lineItemTotalPrice(transaction, isProvider, intl);

  return (
    <>
      <div className={css.lineItemTotal}>
        <div className={css.totalLabel}>{label}</div>
        <div className={css.totalPrice}>{price}</div>
      </div>
    </>
  );
};

LineItemUnitPrice.propTypes = {
  transaction: propTypes.transaction.isRequired,
  isProvider: bool.isRequired,
  intl: intlShape.isRequired,
};

export default LineItemUnitPrice;
