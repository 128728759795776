import React from 'react';
import { FormattedMessage } from '../../util/reactIntl';
import config from '../../config.js';

import css from './SectionBookingRequirementsMaybe.module.css';

const SectionBookingRequirementsMaybe = props => {
  const { publicData } = props;
  if (!publicData || !publicData.minDuration) {
    return null;
  }

  const minDuration = publicData.minDuration;
  const minDurationOption = config.custom.minDurationOptions.find(
    option => option.hours === parseInt(minDuration)
  );

  return (
    <div className={css.sectionBookingRequirements}>
      <h2 className={css.minDurationTitle}>
        <FormattedMessage id="ListingPage.minDurationTitle" />
      </h2>
      <FormattedMessage
        id="ListingPage.minDuration"
        values={{ minDuration: minDurationOption.label }}
      />
    </div>
  );
};

export default SectionBookingRequirementsMaybe;
