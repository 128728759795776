import React, { useState } from 'react';
import { object, string, func, bool, array } from 'prop-types';
import { Form as FinalForm } from 'react-final-form';
import {
  Form,
  Button,
  FieldTextInput,
  SecondaryButton,
  Modal,
  FieldRadioButton,
} from '../../components';
import { composeValidators, validPositiveNumber } from '../../util/validators';

import css from './PremiumFeatures.module.css';
import classNames from 'classnames';

const PremiumFeatures = props => {
  const {
    listing,
    initialDiscounts = [],
    onSave,
    className,
    rootClassName,
    errors,
    onOpenPremiumPriceChangeModal,
    onManageDisableScrolling,
    updateInProgress,
    ...restProps
  } = props;

  const [isMarketValueModalOpen, setIsMarketValueModalOpen] = useState(false);
  const [isInstantBookModalOpen, setIsInstantBookModalOpen] = useState(false);
  const [instantBookEnabled, setInstantBookEnabled] = useState(
    listing?.attributes?.publicData?.instantBook || false
  );

  const trailerValue = listing?.attributes?.privateData?.marketValue || 0;
  const monthlyPrice = listing?.attributes?.privateData?.subscription?.monthlyPrice || 20;

  const onSubmit = async values => {
    try {
      // Transform form values into array of discount objects
      const formattedDiscounts = Object.keys(values)
        .reduce((acc, key) => {
          const match = key.match(/discount-(\d+)-(code|percentDiscount)/);
          if (match) {
            const [, index, field] = match;
            if (!acc[index]) {
              acc[index] = {};
            }
            acc[index][field === 'code' ? 'code' : 'percentDiscount'] = values[key];
          }
          return acc;
        }, [])
        .filter(discount => discount.code && discount.percentDiscount); // Only include complete discount pairs
      // Save discount codes and instant book setting
      const saveData = {
        privateData: {
          discountCodes: formattedDiscounts,
        },
        publicData: {
          instantBook: values.instantBook === 'enabled',
        },
      };
      await onSave(saveData);
    } catch (error) {
      console.error('Error saving changes:', error);
    }
  };

  const handleSubmitMarketValue = values => {
    const newMarketValue = Number(values.marketValue);
    onOpenPremiumPriceChangeModal(newMarketValue);
    setIsMarketValueModalOpen(false);
  };

  const handleInstantBookChange = e => {
    const newValue = e.target.value;
    if (newValue === 'enabled') {
      setIsInstantBookModalOpen(true);
    } else {
      setInstantBookEnabled(false);
    }
  };

  const confirmInstantBook = () => {
    setInstantBookEnabled(true);
    setIsInstantBookModalOpen(false);
  };

  return (
    <FinalForm
      {...restProps}
      onSubmit={onSubmit}
      initialValues={{
        // Set up initial discount fields from props
        ...initialDiscounts.reduce(
          (acc, discount, index) => ({
            ...acc,
            [`discount-${index}-code`]: discount.code,
            [`discount-${index}-percentDiscount`]: discount.percentDiscount,
          }),
          {}
        ),
        instantBook: instantBookEnabled ? 'enabled' : 'disabled',
      }}
      mutators={{
        removeFields: ([index], state, { changeValue }) => {
          changeValue(state, `discount-${index}-code`, () => undefined);
          changeValue(state, `discount-${index}-percentDiscount`, () => undefined);
        },
        addFields: ([], state, { changeValue }) => {
          // Get current number of discount fields
          const currentFields = Object.keys(state.fields).filter(key =>
            key.startsWith('discount-')
          );
          const nextIndex = currentFields.length / 2; // Divide by 2 because we have two fields per discount

          // Add new empty fields
          changeValue(state, `discount-${nextIndex}-code`, () => '');
          changeValue(state, `discount-${nextIndex}-percentDiscount`, () => '');
        },
      }}
      render={formRenderProps => {
        const { handleSubmit, form, values } = formRenderProps;
        const classes = classNames(css.root, className);

        const removeDiscount = index => {
          form.mutators.removeFields(index);
        };

        const addDiscount = () => {
          form.mutators.addFields();
        };

        // Calculate how many discount fields we have
        const discountFields = Object.keys(values)
          .filter(key => key.startsWith('discount-'))
          .reduce((acc, key) => {
            const match = key.match(/discount-(\d+)/);
            if (match) {
              acc.add(Number(match[1]));
            }
            return acc;
          }, new Set());

        const title = `${listing?.attributes?.title || 'This listing'} is a Premium listing`;

        return (
          <>
            <Form className={classes} onSubmit={handleSubmit}>
              <h2>{title}</h2>
              <div>
                <h2>Discount Codes</h2>
                <p>
                  Enter a discount code (up to 20 characters) and a percentage discount (1-90). You
                  can give these codes to your customers to promote repeat business or encourage new
                  customers to use your listing.
                </p>
                <p>You can choose the percentage discount and can remove and add new codes.</p>
                {Array.from(discountFields).map(index => (
                  <div key={`new-${index}`} className={css.discountRow}>
                    <FieldTextInput
                      type="text"
                      name={`discount-${index}-code`}
                      id={`discount-${index}-code`}
                      placeholder="Discount Code"
                    />
                    <FieldTextInput
                      type="number"
                      name={`discount-${index}-percentDiscount`}
                      id={`discount-${index}-percentDiscount`}
                      placeholder="%"
                    />
                    <Button
                      small
                      warning
                      type="button"
                      className={css.removeButton}
                      onClick={() => removeDiscount(index)}
                    >
                      Remove
                    </Button>
                  </div>
                ))}
                <div className={css.buttonWrapper}>
                  <SecondaryButton
                    small
                    className={css.addButton}
                    onClick={addDiscount}
                    type="button"
                  >
                    Add Discount Code
                  </SecondaryButton>
                </div>
              </div>

              <div className={css.instantBookSection}>
                <h2>Instant Book</h2>
                <p>
                  When enabled, bookings will be automatically accepted without requiring your
                  manual approval. This can help increase bookings and provide a better customer
                  experience.
                </p>
                <div className={css.radioGroup}>
                  <FieldRadioButton
                    id="instantBookDisabled"
                    name="instantBook"
                    label="Disabled"
                    value="disabled"
                    onChange={handleInstantBookChange}
                  />
                  <FieldRadioButton
                    id="instantBookEnabled"
                    name="instantBook"
                    label="Enabled"
                    value="enabled"
                    onChange={handleInstantBookChange}
                  />
                </div>
              </div>

              <div className={css.trailerValueSection}>
                <div className={css.trailerValueRow}>
                  <div className={css.trailerValue}>
                    <span>Current trailer value: ${trailerValue}</span>
                    <span>Monthly premium: ${monthlyPrice}</span>
                  </div>
                  <Button
                    className={css.changeValueButton}
                    onClick={() => setIsMarketValueModalOpen(true)}
                    small
                  >
                    Change market value
                  </Button>
                </div>
              </div>
              <div>
                <Button type="submit" disabled={updateInProgress}>
                  {updateInProgress ? 'Updating...' : 'Save All Changes'}
                </Button>
              </div>
            </Form>

            <Modal
              id="MarketValueChange"
              isOpen={isMarketValueModalOpen}
              onClose={() => setIsMarketValueModalOpen(false)}
              onManageDisableScrolling={onManageDisableScrolling}
              containerClassName={css.modalContainer}
            >
              <FinalForm
                onSubmit={handleSubmitMarketValue}
                initialValues={{ marketValue: trailerValue }}
                render={({ handleSubmit: handleMarketValueSubmit, submitting, valid }) => (
                  <Form onSubmit={handleMarketValueSubmit}>
                    <h2>Update Trailer Value</h2>
                    <FieldTextInput
                      id="marketValue"
                      name="marketValue"
                      className={css.marketValueInput}
                      type="number"
                      label="Trailer Value ($)"
                      validate={composeValidators(validPositiveNumber('Must be a positive number'))}
                    />
                    <div className={css.modalButtons}>
                      <SecondaryButton
                        className={css.cancelButton}
                        onClick={() => setIsMarketValueModalOpen(false)}
                        small
                      >
                        Cancel
                      </SecondaryButton>
                      <Button type="submit" disabled={updateInProgress}>
                        Update Value
                      </Button>
                    </div>
                  </Form>
                )}
              />
            </Modal>

            <Modal
              id="InstantBookConfirmation"
              isOpen={isInstantBookModalOpen}
              onClose={() => setIsInstantBookModalOpen(false)}
              onManageDisableScrolling={onManageDisableScrolling}
              containerClassName={css.modalContainer}
            >
              <div className={css.modalContent}>
                <h2>Enable Instant Book</h2>
                <p>
                  By enabling Instant Book, all bookings will be automatically accepted without
                  requiring your manual approval. This means customers can book your trailer
                  immediately without waiting for your confirmation.
                </p>
                <p>Are you sure you want to enable Instant Book?</p>
                <div className={css.modalButtons}>
                  <SecondaryButton
                    className={css.cancelButton}
                    onClick={() => setIsInstantBookModalOpen(false)}
                    small
                  >
                    Cancel
                  </SecondaryButton>
                  <Button onClick={confirmInstantBook} small>
                    Enable Instant Book
                  </Button>
                </div>
              </div>
            </Modal>
          </>
        );
      }}
    />
  );
};

PremiumFeatures.defaultProps = {
  className: null,
  rootClassName: null,
  errors: null,
  listing: null,
  initialDiscounts: [],
};

PremiumFeatures.propTypes = {
  className: string,
  rootClassName: string,
  initialDiscounts: array,
  errors: object,

  // We cannot use propTypes.listing since the listing might be a draft.
  listing: object,
  onOpenPremiumPriceChangeModal: func.isRequired,
  onSave: func.isRequired,
  onManageDisableScrolling: func.isRequired,
  updateInProgress: bool.isRequired,
};

export default PremiumFeatures;
