import React, { useState } from 'react';
import { arrayOf, bool, func, object, string } from 'prop-types';
import classNames from 'classnames';
import { FormattedMessage } from '../../util/reactIntl';
import { ensureOwnListing } from '../../util/data';
import { getDefaultTimeZoneOnBrowser, timestampToDate } from '../../util/dates';
import { LISTING_STATE_DRAFT, DATE_TYPE_DATETIME, propTypes } from '../../util/types';
import {
  Button,
  IconClose,
  IconEdit,
  IconSpinner,
  InfoBox,
  InlineTextButton,
  ListingLink,
  Modal,
  TimeRange,
} from '../../components';
import {
  EditListingAvailabilityPlanForm,
  EditListingAvailabilityExceptionForm,
  EditListingBookingRequirementsForm,
} from '../../forms';
import { timeOfDayStringTo12HourClock } from '../../util/dates.js';
import config from '../../config';

import css from './EditListingAvailabilityPanel.module.css';

const WEEKDAYS = ['mon', 'tue', 'wed', 'thu', 'fri', 'sat', 'sun'];

// We want to sort exceptions on the client-side, maximum pagination page size is 100,
// so we need to restrict the amount of exceptions to that.
const MAX_EXCEPTIONS_COUNT = 100;

const defaultTimeZone = () =>
  typeof window !== 'undefined' ? getDefaultTimeZoneOnBrowser() : 'Australia/Melbourne';

/////////////
// Weekday //
/////////////
const findEntry = (availabilityPlan, dayOfWeek) =>
  availabilityPlan.entries.find(d => d.dayOfWeek === dayOfWeek);

const getEntries = (availabilityPlan, dayOfWeek) =>
  availabilityPlan.entries.filter(d => d.dayOfWeek === dayOfWeek);

const Weekday = props => {
  const { availabilityPlan, dayOfWeek, openEditModal } = props;
  const hasEntry = findEntry(availabilityPlan, dayOfWeek);

  return (
    <div
      className={classNames(css.weekDay, { [css.blockedWeekDay]: !hasEntry })}
      onClick={() => openEditModal(true)}
      role="button"
    >
      <div className={css.dayOfWeek}>
        <FormattedMessage id={`EditListingAvailabilityPanel.dayOfWeek.${dayOfWeek}`} />
      </div>
      <div className={css.entries}>
        {availabilityPlan && hasEntry
          ? getEntries(availabilityPlan, dayOfWeek).map(e => {
              return (
                <span
                  className={css.entry}
                  key={`${e.dayOfWeek}${e.startTime}`}
                >{`${timeOfDayStringTo12HourClock(e.startTime)} - ${
                  e.endTime === '00:00' ? '24:00' : timeOfDayStringTo12HourClock(e.endTime)
                }`}</span>
              );
            })
          : null}
      </div>
    </div>
  );
};

///////////////////////////////////////////////////
// EditListingAvailabilityExceptionPanel - utils //
///////////////////////////////////////////////////

// Create initial entry mapping for form's initial values
const createEntryDayGroups = (entries = {}) =>
  entries.reduce((groupedEntries, entry) => {
    const { startTime, endTime: endHour, dayOfWeek } = entry;
    const dayGroup = groupedEntries[dayOfWeek] || [];
    return {
      ...groupedEntries,
      [dayOfWeek]: [
        ...dayGroup,
        {
          startTime,
          endTime: endHour === '00:00' ? '24:00' : endHour,
        },
      ],
    };
  }, {});

// Create initial values
const createInitialValues = (availabilityPlan, openingHours) => {
  const { timezone } = availabilityPlan || {};
  const tz = timezone || defaultTimeZone();
  return {
    ...createInitialOpeningHours(openingHours),
    timezone: tz,
  };
};

// Create entries from submit values
const createEntriesFromSubmitValues = values =>
  WEEKDAYS.reduce((allEntries, dayOfWeek) => {
    const dayValues = values[dayOfWeek] || [];
    const dayEntries = dayValues.map(dayValue => {
      const { startTime, endTime } = dayValue;
      // Note: This template doesn't support seats yet.
      return startTime && endTime
        ? {
            dayOfWeek,
            seats: 1,
            startTime,
            endTime: endTime === '24:00' ? '00:00' : endTime,
          }
        : null;
    });

    return allEntries.concat(dayEntries.filter(e => !!e));
  }, []);

// Create availabilityPlan from submit values
const createAvailabilityPlan = values => ({
  availabilityPlan: {
    type: 'availability-plan/time',
    timezone: values.timezone,
    entries: [
      { dayOfWeek: 'mon', startTime: '00:00', endTime: '00:00', seats: 1 },
      { dayOfWeek: 'tue', startTime: '00:00', endTime: '00:00', seats: 1 },
      { dayOfWeek: 'wed', startTime: '00:00', endTime: '00:00', seats: 1 },
      { dayOfWeek: 'thu', startTime: '00:00', endTime: '00:00', seats: 1 },
      { dayOfWeek: 'fri', startTime: '00:00', endTime: '00:00', seats: 1 },
      { dayOfWeek: 'sat', startTime: '00:00', endTime: '00:00', seats: 1 },
      { dayOfWeek: 'sun', startTime: '00:00', endTime: '00:00', seats: 1 },
    ],
  },
});

// Opening Hours

// Create initial values

const defaultOpeningHours = {
  entries: [
    { dayOfWeek: 'mon', startTime: '06:00', endTime: '22:00' },
    { dayOfWeek: 'tue', startTime: '06:00', endTime: '22:00' },
    { dayOfWeek: 'wed', startTime: '06:00', endTime: '22:00' },
    { dayOfWeek: 'thu', startTime: '06:00', endTime: '22:00' },
    { dayOfWeek: 'fri', startTime: '06:00', endTime: '22:00' },
    { dayOfWeek: 'sat', startTime: '06:00', endTime: '22:00' },
    { dayOfWeek: 'sun', startTime: '06:00', endTime: '22:00' },
  ],
};

const createInitialOpeningHours = openingHours => {
  const { entries } = openingHours || {};
  return {
    ...createEntryDayGroups(entries),
  };
};

const defaultOpeningHoursAndAvailabilityPlan = {
  ...createAvailabilityPlan({ timezone: defaultTimeZone() }),
  publicData: {
    openingHours: defaultOpeningHours,
  },
};

const getAvailabilityPlanMaybe = (currentListing, values) => {
  if (
    !!currentListing.attributes.availabilityPlan &&
    values.timezone === currentListing.attributes.availabilityPlan.timezone
  ) {
    // don't set the availability plan if we already have one and the timezone isn't being updated
    // this is necessary to allow admins to update the opening hours when acting as a user (because admins aren't permitted to update the availability plan while acting as a user)
    return null;
  }
  return createAvailabilityPlan(values);
};

const createOpeningHoursAndAvailabilityPlan = (currentListing, values) => ({
  ...getAvailabilityPlanMaybe(currentListing, values),
  publicData: {
    openingHours: {
      entries: createEntriesFromSubmitValues(values),
    },
  },
});

// Ensure that the AvailabilityExceptions are in sensible order.
//
// Note: if you allow fetching more than 100 exception,
// pagination kicks in and that makes client-side sorting impossible.
const sortExceptionsByStartTime = (a, b) => {
  return a.attributes.start.getTime() - b.attributes.start.getTime();
};

//////////////////////////////////
// EditListingAvailabilityPanel //
//////////////////////////////////
const EditListingAvailabilityPanel = props => {
  const {
    className,
    rootClassName,
    listing,
    availabilityExceptions,
    fetchExceptionsInProgress,
    onAddAvailabilityException,
    onDeleteAvailabilityException,
    disabled,
    ready,
    onSubmit,
    onManageDisableScrolling,
    onNextTab,
    submitButtonText,
    updateInProgress,
    errors,
  } = props;
  // Hooks
  const [isEditPlanModalOpen, setIsEditPlanModalOpen] = useState(false);
  const [isEditBookingRequirementsModalOpen, setIsEditBookingRequirementsModalOpen] = useState(
    false
  );
  const [isEditExceptionsModalOpen, setIsEditExceptionsModalOpen] = useState(false);
  const [valuesFromLastSubmit, setValuesFromLastSubmit] = useState(null);

  const classes = classNames(rootClassName || css.root, className);
  const currentListing = ensureOwnListing(listing);
  const { publicData } = currentListing.attributes;

  const isPublished = currentListing.id && currentListing.attributes.state !== LISTING_STATE_DRAFT;
  const defaultAvailabilityPlan = {
    type: 'availability-plan/time',
    timezone: defaultTimeZone(),
    entries: [
      { dayOfWeek: 'mon', startTime: '00:00', endTime: '00:00', seats: 1 },
      { dayOfWeek: 'tue', startTime: '00:00', endTime: '00:00', seats: 1 },
      { dayOfWeek: 'wed', startTime: '00:00', endTime: '00:00', seats: 1 },
      { dayOfWeek: 'thu', startTime: '00:00', endTime: '00:00', seats: 1 },
      { dayOfWeek: 'fri', startTime: '00:00', endTime: '00:00', seats: 1 },
      { dayOfWeek: 'sat', startTime: '00:00', endTime: '00:00', seats: 1 },
      { dayOfWeek: 'sun', startTime: '00:00', endTime: '00:00', seats: 1 },
    ],
  };
  const availabilityPlan = currentListing.attributes.availabilityPlan || defaultAvailabilityPlan;
  const openingHours = publicData.openingHours || defaultOpeningHours;

  const defaultLeadTime = 60;
  const leadTime = publicData.leadTime !== undefined ? publicData.leadTime : defaultLeadTime;
  const minDuration = publicData.minDuration || 0;
  const leadTimeOption = config.custom.leadTimeOptions.find(
    option => option.minutes === parseInt(leadTime)
  );
  const minDurationOption = config.custom.minDurationOptions.find(
    option => option.hours === parseInt(minDuration)
  );
  const leadTimeFriendly =
    leadTimeOption.minutes === 0
      ? 'No advance notice is required for booking this trailer.'
      : `Customers must book at least ${leadTimeOption.label} before their intended pick-up time.`;

  const minDurationFriendly =
    minDurationOption.hours === 0
      ? 'There is no minimum hire duration required for booking this trailer.'
      : `Hires must be at least ${minDurationOption.label} long.`;

  const initialBookingRequirementsValues = { leadTime: leadTime, minDuration: minDuration };

  const saveBookingRequirements = values => {
    const updateValues = { publicData: values };
    return onSubmit(updateValues)
      .then(() => {
        setIsEditBookingRequirementsModalOpen(false);
      })
      .catch(e => {
        // Don't close modal if there was an error
      });
  };

  const submitWithDefaultAvailabilityPlan = () => {
    // saving with form button (goToNextTabButton)
    if (!publicData.leadTime && !currentListing.attributes.availabilityPlan) {
      const defaultOpeningHoursAndAvailabilityPlanAndLeadTime = defaultOpeningHoursAndAvailabilityPlan;
      defaultOpeningHoursAndAvailabilityPlanAndLeadTime.publicData['leadTime'] = defaultLeadTime;
      return onSubmit(defaultOpeningHoursAndAvailabilityPlanAndLeadTime).then(onNextTab);
    }
    if (!publicData.leadTime) {
      return onSubmit({ publicData: { leadTime: defaultLeadTime } }).then(onNextTab);
    }
    if (!currentListing.attributes.availabilityPlan) {
      return onSubmit(defaultOpeningHoursAndAvailabilityPlan).then(onNextTab);
    }
    return onNextTab();
  };

  const initialValues = valuesFromLastSubmit
    ? valuesFromLastSubmit
    : createInitialValues(availabilityPlan, openingHours);

  const handleSubmit = values => {
    setValuesFromLastSubmit(values);

    // Final Form can wait for Promises to return.
    return onSubmit(createOpeningHoursAndAvailabilityPlan(currentListing, values))
      .then(() => {
        setIsEditPlanModalOpen(false);
      })
      .catch(e => {
        // Don't close modal if there was an error
      });
  };

  const exceptionCount = availabilityExceptions ? availabilityExceptions.length : 0;
  const sortedAvailabilityExceptions = availabilityExceptions.sort(sortExceptionsByStartTime);

  // Save exception click handler
  const saveException = values => {
    const { availability, exceptionStartTime, exceptionEndTime } = values;

    // TODO: add proper seat handling
    const seats = availability === 'available' ? 1 : 0;

    return onAddAvailabilityException({
      listingId: listing.id,
      seats,
      start: timestampToDate(exceptionStartTime),
      end: timestampToDate(exceptionEndTime),
    })
      .then(() => {
        setIsEditExceptionsModalOpen(false);
      })
      .catch(e => {
        // Don't close modal if there was an error
      });
  };

  const bookingRequirementsSection = (
    <section className={css.bookingRequirements}>
      <header className={css.sectionHeader}>
        <h2 className={css.sectionTitle}>
          <FormattedMessage id="EditListingAvailabilityPanel.bookingRequirementsTitle" />
        </h2>
        <InlineTextButton
          className={css.editPlanButton}
          onClick={() => setIsEditBookingRequirementsModalOpen(true)}
        >
          <IconEdit className={css.editPlanIcon} />{' '}
          <FormattedMessage id="EditListingAvailabilityPanel.edit" />
        </InlineTextButton>
      </header>
      <div className={css.week}>
        <div className={css.weekDay} onClick={() => setIsEditBookingRequirementsModalOpen(true)}>
          <p className={css.requirementValue}>{leadTimeFriendly}</p>
        </div>
        <div className={css.weekDay} onClick={() => setIsEditBookingRequirementsModalOpen(true)}>
          <p className={css.requirementValue}>{minDurationFriendly}</p>
        </div>
      </div>
      {minDurationOption.hours > 0 ? (
        <InfoBox
          content={
            'Tip: removing your minimum hire duration could help you attract more hires and maximize your earnings.'
          }
        />
      ) : null}
    </section>
  );

  const unavailableTimesSection = (
    <>
      {bookingRequirementsSection}
      <section className={css.section}>
        <header className={css.sectionHeader}>
          <h2 className={css.sectionTitle}>
            <FormattedMessage id="EditListingAvailabilityPanel.defaultScheduleTitle" />
          </h2>
          <InlineTextButton
            className={css.editPlanButton}
            onClick={() => setIsEditPlanModalOpen(true)}
          >
            <IconEdit className={css.editPlanIcon} />{' '}
            <FormattedMessage id="EditListingAvailabilityPanel.edit" />
          </InlineTextButton>
        </header>
        <div className={css.week}>
          {WEEKDAYS.map(w => (
            <Weekday
              dayOfWeek={w}
              key={w}
              availabilityPlan={openingHours}
              openEditModal={setIsEditPlanModalOpen}
            />
          ))}
        </div>
        <div className={css.timezoneAndNotesWrapper} onClick={() => setIsEditPlanModalOpen(true)}>
          <div className={css.timezone}>
            <FormattedMessage id="EditListingAvailabilityPanel.timezone" />
          </div>
          <div>{availabilityPlan.timezone}</div>
        </div>
      </section>
    </>
  );

  return (
    <main className={classes}>
      <h1 className={css.title}>
        {isPublished ? (
          <FormattedMessage
            id="EditListingAvailabilityPanel.title"
            values={{
              listingTitle: (
                <ListingLink listing={listing}>{currentListing.attributes.title}</ListingLink>
              ),
            }}
          />
        ) : (
          <FormattedMessage id="EditListingAvailabilityPanel.createListingTitle" />
        )}
      </h1>
      {isPublished ? null : unavailableTimesSection}

      <section className={css.section}>
        <header className={css.sectionHeader}>
          <h2 className={css.sectionTitle}>
            {fetchExceptionsInProgress ? (
              <FormattedMessage id="EditListingAvailabilityPanel.availabilityExceptionsTitleNoCount" />
            ) : (
              <FormattedMessage
                id="EditListingAvailabilityPanel.availabilityExceptionsTitle"
                values={{ count: exceptionCount }}
              />
            )}
          </h2>
        </header>

        {fetchExceptionsInProgress ? (
          <div className={css.exceptionsLoading}>
            <IconSpinner />
          </div>
        ) : exceptionCount === 0 ? (
          <div className={css.noExceptions}>
            <FormattedMessage id="EditListingAvailabilityPanel.noExceptions" />
          </div>
        ) : (
          <div className={css.exceptions}>
            {sortedAvailabilityExceptions.map(availabilityException => {
              const { start, end, seats } = availabilityException.attributes;
              return (
                <div key={availabilityException.id.uuid} className={css.exception}>
                  <div className={css.exceptionHeader}>
                    <div className={css.exceptionAvailability}>
                      <div
                        className={classNames(css.exceptionAvailabilityDot, {
                          [css.isAvailable]: seats > 0,
                        })}
                      />
                      <div className={css.exceptionAvailabilityStatus}>
                        <TimeRange
                          className={css.timeRange}
                          startDate={start}
                          endDate={end}
                          dateType={DATE_TYPE_DATETIME}
                          timeZone={availabilityPlan.timezone}
                        />
                      </div>
                    </div>
                    <button
                      className={css.removeExceptionButton}
                      onClick={() =>
                        onDeleteAvailabilityException({ id: availabilityException.id })
                      }
                    >
                      <IconClose size="normal" className={css.removeIcon} />
                    </button>
                  </div>
                </div>
              );
            })}
          </div>
        )}
        {exceptionCount <= MAX_EXCEPTIONS_COUNT ? (
          <InlineTextButton
            className={css.addExceptionButton}
            onClick={() => setIsEditExceptionsModalOpen(true)}
            disabled={disabled}
            ready={ready}
          >
            <FormattedMessage id="EditListingAvailabilityPanel.addException" />
          </InlineTextButton>
        ) : null}
      </section>

      {isPublished ? unavailableTimesSection : null}

      {errors.showListingsError ? (
        <p className={css.error}>
          <FormattedMessage id="EditListingAvailabilityPanel.showListingFailed" />
        </p>
      ) : null}

      {!isPublished ? (
        <Button
          className={css.goToNextTabButton}
          inProgress={updateInProgress}
          onClick={submitWithDefaultAvailabilityPlan}
        >
          {submitButtonText}
        </Button>
      ) : null}
      {onManageDisableScrolling ? (
        <Modal
          id="EditAvailabilityPlan"
          isOpen={isEditPlanModalOpen}
          onClose={() => setIsEditPlanModalOpen(false)}
          onManageDisableScrolling={onManageDisableScrolling}
          containerClassName={css.modalContainer}
          usePortal
        >
          <EditListingAvailabilityPlanForm
            formId="EditListingAvailabilityPlanForm"
            listingTitle={currentListing.attributes.title}
            weekdays={WEEKDAYS}
            onSubmit={handleSubmit}
            initialValues={initialValues}
            inProgress={updateInProgress}
            fetchErrors={errors}
            keepDirtyOnReinitialize={true}
          />
        </Modal>
      ) : null}
      {onManageDisableScrolling ? (
        <Modal
          id="EditBookingRequirements"
          isOpen={isEditBookingRequirementsModalOpen}
          onClose={() => setIsEditBookingRequirementsModalOpen(false)}
          onManageDisableScrolling={onManageDisableScrolling}
          containerClassName={css.modalContainer}
          usePortal
        >
          <EditListingBookingRequirementsForm
            formId="EditListingBookingRequirementsForm"
            listingTitle={currentListing.attributes.title}
            initialValues={initialBookingRequirementsValues}
            onSubmit={values => saveBookingRequirements(values)}
            updateInProgress={updateInProgress}
            fetchErrors={errors}
          />
        </Modal>
      ) : null}
      {onManageDisableScrolling ? (
        <Modal
          id="EditAvailabilityExceptions"
          isOpen={isEditExceptionsModalOpen}
          onClose={() => setIsEditExceptionsModalOpen(false)}
          onManageDisableScrolling={onManageDisableScrolling}
          containerClassName={css.modalContainer}
          usePortal
        >
          <EditListingAvailabilityExceptionForm
            formId="EditListingAvailabilityExceptionForm"
            onSubmit={saveException}
            timeZone={availabilityPlan.timezone}
            availabilityExceptions={sortedAvailabilityExceptions}
            updateInProgress={updateInProgress}
            fetchErrors={errors}
          />
        </Modal>
      ) : null}
    </main>
  );
};

EditListingAvailabilityPanel.defaultProps = {
  className: null,
  rootClassName: null,
  listing: null,
  availabilityExceptions: [],
};

EditListingAvailabilityPanel.propTypes = {
  className: string,
  rootClassName: string,

  // We cannot use propTypes.listing since the listing might be a draft.
  listing: object,
  disabled: bool.isRequired,
  ready: bool.isRequired,
  availabilityExceptions: arrayOf(propTypes.availabilityException),
  fetchExceptionsInProgress: bool.isRequired,
  onAddAvailabilityException: func.isRequired,
  onDeleteAvailabilityException: func.isRequired,
  onSubmit: func.isRequired,
  onManageDisableScrolling: func.isRequired,
  onNextTab: func.isRequired,
  submitButtonText: string.isRequired,
  updateInProgress: bool.isRequired,
  errors: object.isRequired,
};

export default EditListingAvailabilityPanel;
