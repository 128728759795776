import React, { useState } from 'react';
import { bool, func, object, string } from 'prop-types';
import classNames from 'classnames';
import { FormattedMessage } from '../../util/reactIntl';
import { ensureOwnListing } from '../../util/data';
import { Button, ListingLink, Modal, PremiumFeatures, Subscription } from '..';
import moment from 'moment';

import css from './EditListingSubscriptionPanel.module.css';

const EditListingSubscriptionPanel = props => {
  const {
    className,
    rootClassName,
    listing,
    submitButtonText,
    panelUpdated,
    updateInProgress,
    onSubmit,
    errors,
    intl,
    onManageDisableScrolling,
  } = props;

  const classes = classNames(rootClassName || css.root, className);
  const currentListing = ensureOwnListing(listing);
  const { publicData } = currentListing.attributes;
  const { privateData } = currentListing.attributes;
  const savedSubscription = publicData.subscription;
  const discountCodes = Array.isArray(privateData?.discountCodes) ? privateData.discountCodes : [];

  const calculatePremiumPrice = marketValue => {
    return marketValue && marketValue >= 20
      ? Math.max(Number(marketValue) / 24, 20).toFixed(2)
      : '20';
  };

  const savedMonthlyPrice =
    privateData?.subscription?.monthlyPrice || calculatePremiumPrice(privateData?.marketValue);

  const savedSubscriptionSelectedOn = privateData?.subscription?.selectedOn || null;
  const savedSubscriptionPaidUntil = !!savedSubscriptionSelectedOn
    ? moment(savedSubscriptionSelectedOn)
        .add(1, 'month')
        .toISOString()
    : null;

  const [state, setState] = useState({
    selectedListingType: savedSubscription || 'premium',
    nextBillingDate: savedSubscriptionPaidUntil,
    isTrailerValueSet: !!privateData.marketValue,
    isTrailerValueSaving: false,
    monthlyPrice: savedMonthlyPrice,
    subscriptionSelectedOn: savedSubscriptionSelectedOn,
    isPremiumPriceChangeModalOpen: false,
  });

  const handleListingTypeSelect = type => {
    const currentDate = new Date().toISOString();

    setState(prevState => ({
      ...prevState,
      selectedListingType: type,
      subscriptionSelectedOn: currentDate,
    }));
  };

  const handleSubmitSubscription = () => {
    const price = state.selectedListingType === 'premium' ? state.monthlyPrice : '0';

    onSubmit({
      subscription: state.selectedListingType,
      subscriptionSelectedOn: state.subscriptionSelectedOn,
      monthlyPrice: price,
    });

    setState(prevState => ({
      ...prevState,
      isTrailerValueSaving: false,
      // isPremiumPriceChangeModalOpen: false,
    }));
  };

  const handleSubmitMarketValue = values => {
    const newMonthlyPrice = calculatePremiumPrice(values.marketValue);

    setState(prevState => ({
      ...prevState,
      isTrailerValueSaving: true,
      monthlyPrice: newMonthlyPrice,
    }));
    onSubmit({ marketValue: values.marketValue });
    setState(prevState => ({
      ...prevState,
      isTrailerValueSet: true,
    }));
    if (newMonthlyPrice !== state.monthlyPrice && savedSubscription === 'premium') {
      setState(prevState => ({
        ...prevState,
        isPremiumPriceChangeModalOpen: true,
      }));
    }
  };

  const handleMarketValueChange = newValue => {
    const newMonthlyPrice = calculatePremiumPrice(newValue);

    setState(prevState => ({
      ...prevState,
      isTrailerValueSaving: true,
      monthlyPrice: newMonthlyPrice,
    }));

    const updateValues = {
      privateData: {
        ...currentListing.attributes.privateData,
        marketValue: newValue.toString(), // Ensure it's a string
        subscription: {
          ...currentListing.attributes.privateData.subscription,
          monthlyPrice: newMonthlyPrice.toString(), // Ensure it's a string
          selectedOn: currentListing.attributes.privateData.subscription.selectedOn,
        },
      },
    };

    onSubmit(updateValues);

    // Show price change modal if needed
    if (newMonthlyPrice !== savedMonthlyPrice && savedSubscription === 'premium') {
      setState(prevState => ({
        ...prevState,
        isPremiumPriceChangeModalOpen: true,
      }));
    }
  };

  return (
    <div className={classes}>
      <h1 className={css.title}>
        {savedSubscription === 'premium' && !!savedSubscriptionPaidUntil ? (
          <>
            <FormattedMessage
              id="EditListingSubscriptionPanel.title"
              values={{
                listingTitle: (
                  <ListingLink listing={listing}>{currentListing.attributes.title}</ListingLink>
                ),
              }}
            />
            <PremiumFeatures
              initialDiscounts={discountCodes}
              listing={listing}
              onOpenPremiumPriceChangeModal={handleMarketValueChange}
              onManageDisableScrolling={onManageDisableScrolling}
              updateInProgress={updateInProgress}
              onSave={values => {
                const updateValues = {
                  privateData: {
                    ...currentListing.attributes.privateData,
                    ...(values.privateData || {}),
                  },
                  publicData: {
                    ...currentListing.attributes.publicData,
                    ...(values.publicData || {}),
                  },
                };
                onSubmit(updateValues);
              }}
            />
          </>
        ) : (
          <>
            <FormattedMessage
              id="EditListingSubscriptionPanel.titleNew"
              values={{
                listingTitle: (
                  <ListingLink listing={listing}>{currentListing.attributes.title}</ListingLink>
                ),
              }}
            />
            <Subscription
              errors={errors}
              handleSubmitMarketValue={handleSubmitMarketValue}
              handleSubmitSubscription={handleSubmitSubscription}
              handleListingTypeSelect={handleListingTypeSelect}
              intl={intl}
              onManageDisableScrolling={onManageDisableScrolling}
              panelUpdated={panelUpdated}
              privateData={privateData}
              publicData={publicData}
              savedMonthlyPrice={savedMonthlyPrice}
              savedSubscriptionPaidUntil={savedSubscriptionPaidUntil}
              updateInProgress={updateInProgress}
              submitButtonText={submitButtonText}
              isTrailerValueSaving={state.isTrailerValueSaving}
              isTrailerValueSet={state.isTrailerValueSet}
              selectedListingType={state.selectedListingType}
              monthlyPrice={state.monthlyPrice}
              nextBillingDate={state.nextBillingDate}
            />
          </>
        )}
      </h1>
      <Modal
        id="PremiumPriceChange"
        isOpen={state.isPremiumPriceChangeModalOpen}
        onClose={() =>
          setState(prevState => ({
            ...prevState,
            isPremiumPriceChangeModalOpen: false,
          }))
        }
        onManageDisableScrolling={onManageDisableScrolling}
        containerClassName={css.modalContainer}
        usePortal
      >
        By changing the market value of your trailer, you have changed the monthly price. You will
        be billed ${state.monthlyPrice} from the next scheduled billing date {state.nextBillingDate}
        .
        <Button
          className={css.submitButton}
          type="submit"
          inProgress={updateInProgress}
          onClick={handleSubmitSubscription}
          ready={panelUpdated && !state.isTrailerValueSaving}
        >
          Accept
        </Button>
      </Modal>
    </div>
  );
};

EditListingSubscriptionPanel.defaultProps = {
  className: null,
  rootClassName: null,
  errors: null,
  listing: null,
};

EditListingSubscriptionPanel.propTypes = {
  className: string,
  rootClassName: string,

  // We cannot use propTypes.listing since the listing might be a draft.
  listing: object,
  submitButtonText: string.isRequired,
  panelUpdated: bool.isRequired,
  updateInProgress: bool.isRequired,
  onSubmit: func.isRequired,
  errors: object.isRequired,
};

export default EditListingSubscriptionPanel;
