import React, { useEffect, useState } from 'react';
import { string } from 'prop-types';
import { FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';
import { createResourceLocatorString } from '../../util/routes';
import routeConfiguration from '../../routeConfiguration';

import { Button, NamedLink } from '../../components';
import { LocationSearchForm } from '../../forms';
import { latestLocations } from '../../util/locations';
import css from './SectionHero.module.css';

const SectionHero = props => {
  const [mounted, setMounted] = useState(false);
  const { rootClassName, className, history } = props;

  useEffect(() => {
    setMounted(true);
  }, []);

  const classes = classNames(rootClassName || css.root, className);

  const handleSearchSubmit = values => {
    const { search, selectedPlace } = values.location;
    const { origin, bounds } = selectedPlace;
    const searchParams = { address: search, origin, bounds };
    history.push(createResourceLocatorString('SearchPage', routeConfiguration(), {}, searchParams));
  };

  const locationLink = location => {
    return (
      <NamedLink
        key={location.name}
        name="SearchPage"
        to={{ search: location.url }}
        className={css.location}
      >
        <span className={css.linkText}>{location.name}</span>
      </NamedLink>
    );
  };

  return (
    <div className={classes}>
      <div className={css.heroContent}>
        <h1 className={classNames(css.heroMainTitle, { [css.heroMainTitleFEDelay]: mounted })}>
          <FormattedMessage id="SectionHero.title" />
        </h1>
        <h2 className={classNames(css.heroSubTitle, { [css.heroSubTitleFEDelay]: mounted })}>
          <FormattedMessage id="SectionHero.subTitle" />
        </h2>
        <div className={css.searchForm}>
          <LocationSearchForm
            className={classNames({ [css.heroButtonFEDelay]: mounted })}
            onSubmit={handleSearchSubmit}
          />
        </div>
        <div className={css.trending}>Over 970 trailers available for hire across Australia</div>
      </div>
    </div>
  );
};

SectionHero.defaultProps = { rootClassName: null, className: null };

SectionHero.propTypes = {
  rootClassName: string,
  className: string,
};

export default SectionHero;
