import React from 'react';
import { arrayOf, bool, func, shape, string } from 'prop-types';
import { compose } from 'redux';
import { Form as FinalForm } from 'react-final-form';
import { intlShape, injectIntl, FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';
import { propTypes } from '../../util/types';
import {
  required,
  composeValidators,
  atmGreaterThanTare,
  tareLessThanAtm,
  validPositiveNumber,
} from '../../util/validators';
import { Form, Button, FieldTextInput, FieldNumberInput, NamedLink } from '../../components';

import css from './EditListingDescriptionForm.module.css';

const EditListingDescriptionFormComponent = props => (
  <FinalForm
    {...props}
    render={formRenderProps => {
      const {
        className,
        disabled,
        ready,
        handleSubmit,
        intl,
        invalid,
        pristine,
        saveActionMsg,
        updated,
        updateInProgress,
        fetchErrors,
      } = formRenderProps;

      const trailerWeightsLink = (
        <NamedLink name="ExplainWeightsPage" className={css.trailerWeightsLink}>
          <FormattedMessage id="EditListingDescriptionForm.trailerWeightsLink" />
        </NamedLink>
      );

      const descriptionMessage = intl.formatMessage({
        id: 'EditListingDescriptionForm.description',
      });
      const descriptionHint = intl.formatMessage({
        id: 'EditListingDescriptionForm.descriptionHint',
      });
      const descriptionPlaceholderMessage = intl.formatMessage({
        id: 'EditListingDescriptionForm.descriptionPlaceholder',
      });
      const descriptionRequiredMessage = intl.formatMessage({
        id: 'EditListingDescriptionForm.descriptionRequired',
      });

      const registrationMessage = intl.formatMessage({
        id: 'EditListingDescriptionForm.registration',
      });
      const registrationPlaceholderMessage = intl.formatMessage({
        id: 'EditListingDescriptionForm.registrationPlaceholder',
      });
      const registrationRequiredMessage = intl.formatMessage({
        id: 'EditListingDescriptionForm.registrationRequired',
      });

      const yearOfManufactureMessage = intl.formatMessage({
        id: 'EditListingDescriptionForm.yearOfManufacture',
      });
      const yearOfManufactureHint = intl.formatMessage({
        id: 'EditListingDescriptionForm.yearOfManufactureHint',
      });
      const yearOfManufacturePlaceholderMessage = intl.formatMessage({
        id: 'EditListingDescriptionForm.yearOfManufacturePlaceholder',
      });
      const yearOfManufactureRequiredMessage = intl.formatMessage({
        id: 'EditListingDescriptionForm.yearOfManufactureRequired',
      });

      const atmMessage = intl.formatMessage({
        id: 'EditListingDescriptionForm.atm',
      });
      const atmHint = (
        <FormattedMessage
          id="EditListingDescriptionForm.atmHint"
          values={{ trailerWeightsLink: trailerWeightsLink }}
        />
      );
      const atmPlaceholderMessage = intl.formatMessage({
        id: 'EditListingDescriptionForm.atmPlaceholder',
      });
      const atmRequiredMessage = intl.formatMessage({
        id: 'EditListingDescriptionForm.atmRequired',
      });

      const atmInvalidMessage = intl.formatMessage({ id: 'EditListingDescriptionForm.atmInvalid' });
      const tareInvalidMessage = intl.formatMessage({
        id: 'EditListingDescriptionForm.tareInvalid',
      });
      const weightInvalidMessage = intl.formatMessage({
        id: 'EditListingDescriptionForm.weightInvalid',
      });

      const tareMessage = intl.formatMessage({
        id: 'EditListingDescriptionForm.tare',
      });
      const tareHint = (
        <FormattedMessage
          id="EditListingDescriptionForm.tareHint"
          values={{ trailerWeightsLink: trailerWeightsLink }}
        />
      );

      const tarePlaceholderMessage = intl.formatMessage({
        id: 'EditListingDescriptionForm.tarePlaceholder',
      });
      const tareRequiredMessage = intl.formatMessage({
        id: 'EditListingDescriptionForm.tareRequired',
      });

      const { updateListingError, createListingDraftError, showListingsError } = fetchErrors || {};
      const errorMessageUpdateListing = updateListingError ? (
        <p className={css.error}>
          <FormattedMessage id="EditListingDescriptionForm.updateFailed" />
        </p>
      ) : null;

      // This error happens only on first tab (of EditListingWizard)
      const errorMessageCreateListingDraft = createListingDraftError ? (
        <p className={css.error}>
          <FormattedMessage id="EditListingDescriptionForm.createListingDraftError" />
        </p>
      ) : null;

      const errorMessageShowListing = showListingsError ? (
        <p className={css.error}>
          <FormattedMessage id="EditListingDescriptionForm.showListingFailed" />
        </p>
      ) : null;

      const classes = classNames(css.root, className);
      const submitReady = (updated && pristine) || ready;
      const submitInProgress = updateInProgress;
      const submitDisabled = invalid || disabled || submitInProgress;
      const validateAtm = atmGreaterThanTare(atmInvalidMessage);
      const validateTare = tareLessThanAtm(tareInvalidMessage);

      return (
        <Form className={classes} onSubmit={handleSubmit}>
          {errorMessageCreateListingDraft}
          {errorMessageUpdateListing}
          {errorMessageShowListing}
          <p className={css.compliancePlateAdvice}>
            <FormattedMessage id="EditListingDescriptionForm.compliancePlateAdvice" />
          </p>
          <FieldNumberInput
            id="yearOfManufacture"
            name="yearOfManufacture"
            className={css.yearOfManufacture}
            hotjarWhitelist={true}
            label={yearOfManufactureMessage}
            hint={yearOfManufactureHint}
            placeholder={yearOfManufacturePlaceholderMessage}
            validate={required(yearOfManufactureRequiredMessage)}
            autoFocus
          />
          <FieldNumberInput
            id="atm"
            name="atm"
            className={css.atm}
            hotjarWhitelist={true}
            label={atmMessage}
            hint={atmHint}
            placeholder={atmPlaceholderMessage}
            validateFields={['tare']}
            validate={composeValidators(
              required(atmRequiredMessage),
              validateAtm,
              validPositiveNumber(weightInvalidMessage)
            )}
          />
          <FieldNumberInput
            id="tare"
            name="tare"
            className={css.tare}
            hotjarWhitelist={true}
            label={tareMessage}
            hint={tareHint}
            placeholder={tarePlaceholderMessage}
            validateFields={['atm']}
            validate={composeValidators(
              required(tareRequiredMessage),
              validateTare,
              validPositiveNumber(weightInvalidMessage)
            )}
          />
          <FieldTextInput
            id="registration"
            name="registration"
            className={css.registration}
            hotjarWhitelist={true}
            type="text"
            label={registrationMessage}
            placeholder={registrationPlaceholderMessage}
            validate={required(registrationRequiredMessage)}
          />
          <FieldTextInput
            id="description"
            name="description"
            className={css.description}
            hotjarWhitelist={true}
            type="textarea"
            label={descriptionMessage}
            hint={descriptionHint}
            placeholder={descriptionPlaceholderMessage}
            validate={required(descriptionRequiredMessage)}
          />
          <Button
            className={css.submitButton}
            type="submit"
            inProgress={submitInProgress}
            disabled={submitDisabled}
            ready={submitReady}
          >
            {saveActionMsg}
          </Button>
        </Form>
      );
    }}
  />
);

EditListingDescriptionFormComponent.defaultProps = { className: null, fetchErrors: null };

EditListingDescriptionFormComponent.propTypes = {
  className: string,
  intl: intlShape.isRequired,
  onSubmit: func.isRequired,
  saveActionMsg: string.isRequired,
  disabled: bool.isRequired,
  ready: bool.isRequired,
  updated: bool.isRequired,
  updateInProgress: bool.isRequired,
  fetchErrors: shape({
    createListingDraftError: propTypes.error,
    showListingsError: propTypes.error,
    updateListingError: propTypes.error,
  }),
};

export default compose(injectIntl)(EditListingDescriptionFormComponent);
