import React, { useState } from 'react';
import { InlineTextButton } from '../../components';
import { validateDiscountCode } from '../../util/api';

import css from './FieldDiscountCode.module.css';

const FieldDiscountCode = props => {
  const { listingId, intl, onValidDiscountCode } = props;

  const [discountCode, setDiscountCode] = useState('');
  const [status, setStatus] = useState(null);
  const [buttonState, setButtonState] = useState('apply');

  const handleChange = e => {
    setDiscountCode(e.target.value);
    setStatus(null);
    setButtonState('apply');
  };

  const applyDiscount = async () => {
    setButtonState('applying');
    const discountDetails = await validateDiscountCode({
      discountCode: discountCode,
      listingId: listingId.uuid,
    });

    if (discountDetails.isValid) {
      setStatus('success');
      setButtonState('applied');

      onValidDiscountCode(discountCode);
    } else {
      setStatus('error');
      setButtonState('notApplied');

      onValidDiscountCode(null);
    }
  };

  const maxDiscountCodeLength = 20;

  const discountCodeLabel = intl.formatMessage({
    id: 'BookingTimeForm.discountCodeLabel',
  });
  const discountCodePlaceholderMessage = intl.formatMessage({
    id: 'BookingTimeForm.discountCodePlaceholder',
  });

  const inputClass = `${css.textareaDiscountCode} ${
    status === 'success' ? css.inputSuccess : status === 'error' ? css.inputError : ''
  }`;

  const buttonText = {
    apply: 'Apply',
    applying: 'Applying...',
    applied: 'Applied',
    notApplied: 'Not applied',
  }[buttonState];

  const errorMessage = 'Invalid discount code. Please check and try again.';

  return (
    <>
      <div className={css.discountCodeContainer}>
        <input
          id="discountCode"
          className={inputClass}
          type="text"
          label={discountCodeLabel}
          placeholder={discountCodePlaceholderMessage}
          maxLength={maxDiscountCodeLength}
          value={discountCode}
          onChange={handleChange}
        />

        <InlineTextButton
          type="button"
          className={css.applyDiscountButton}
          onClick={applyDiscount}
          disabled={buttonState === 'applying' || !discountCode}
        >
          {buttonText}
        </InlineTextButton>
      </div>
      {status === 'error' && <p className={css.errorMessage}>{errorMessage}</p>}
    </>
  );
};

export default FieldDiscountCode;
