import React from 'react';
import { FormattedMessage } from '../reactIntl';
import { formatMoney } from '../currency';
import { LINE_ITEM_CUSTOMER_LIABILITY_REDUCTION } from '../types';
import {
  TRANSITION_ABORT,
  TRANSITION_ABORT_BY_CUSTOMER,
  TRANSITION_DECLINE,
  TRANSITION_EXPIRE,
} from '../transaction';

export const lineItemLiabilityReductionRefundMaybe = (transaction, isCustomer, intl) => {
  const selectedLiabilityReversal = transaction.attributes.lineItems.find(
    item => item.code === LINE_ITEM_CUSTOMER_LIABILITY_REDUCTION && item.reversal
  );

  const showRefund =
    transaction.attributes.lastTransition !== TRANSITION_EXPIRE &&
    transaction.attributes.lastTransition !== TRANSITION_DECLINE &&
    transaction.attributes.lastTransition !== TRANSITION_ABORT &&
    transaction.attributes.lastTransition !== TRANSITION_ABORT_BY_CUSTOMER;

  if (!selectedLiabilityReversal) {
    return { label: null, detail: null, price: null };
  }

  const liabilityTotalAmount = selectedLiabilityReversal.lineTotal.amount / 100;

  if (liabilityTotalAmount === 0) {
    return { label: null, detail: null, price: null };
  }

  const refundLabel = !!intl ? (
    <FormattedMessage id="BookingBreakdown.liabilityReductionRefund" />
  ) : (
    'Refund Excess Reduction'
  );

  const cancelLabel = !!intl ? (
    <FormattedMessage id="BookingBreakdown.liabilityReductionCancel" />
  ) : (
    'Cancel Excess Reduction'
  );

  if (isCustomer && selectedLiabilityReversal) {
    const formattedCustomerCommissionRefund = !!intl
      ? formatMoney(intl, selectedLiabilityReversal.lineTotal)
      : `$${liabilityTotalAmount.toFixed(2)}`;
    return {
      label: showRefund ? refundLabel : cancelLabel,
      price: formattedCustomerCommissionRefund,
    };
  }
  return {
    label: null,
    price: null,
  };
};
