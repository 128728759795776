import React from 'react';
import { intlShape } from '../../util/reactIntl';
import { propTypes } from '../../util/types';
import { lineItemDiscountCodeMaybe } from '../../util/bookingBreakdown/lineItemDiscountCodeMaybe';
import config from '../../config';

import css from './BookingBreakdown.module.css';

const LineItemDiscountCodeMaybe = props => {
  const { transaction, intl } = props;

  const { label, price, showDiscount } = lineItemDiscountCodeMaybe(transaction, intl);

  const showDiscountCodeLineItem =
    config.custom.featureToggles[config.env].premiumModel && showDiscount;
  if (!showDiscountCodeLineItem) {
    return null;
  }

  return (
    <>
      <div className={css.lineItem}>
        <span className={css.itemLabel}>{label}</span>
        <span className={css.itemValue}>{`${price}`}</span>
      </div>
    </>
  );
};

LineItemDiscountCodeMaybe.propTypes = {
  transaction: propTypes.transaction.isRequired,
  intl: intlShape.isRequired,
};

export default LineItemDiscountCodeMaybe;
