import React from 'react';
import { FormattedMessage } from '../reactIntl';
import { formatMoney } from '../currency';
import { LINE_ITEM_CUSTOMER_LIABILITY_REDUCTION } from '../types';

export const lineItemLiabilityReductionMaybe = (transaction, intl) => {
  const selectedLiability = transaction.attributes.lineItems.find(
    item => item.code === LINE_ITEM_CUSTOMER_LIABILITY_REDUCTION && !item.reversal
  );

  if (!selectedLiability) {
    return { label: null, detail: null, price: null };
  }

  const quantity = parseInt(selectedLiability.quantity, 10);

  const liabilityAmount = selectedLiability.unitPrice.amount / 100;
  if (liabilityAmount === 0) {
    return { label: null, detail: null, price: null };
  }

  const liabilityTotalAmount = selectedLiability.lineTotal.amount / 100;

  const formattedTotalLiabilityAmount = !!intl
    ? formatMoney(intl, selectedLiability.lineTotal)
    : `$${liabilityTotalAmount.toFixed(2)}`;

  const label = !!intl ? (
    <FormattedMessage id="BookingBreakdown.liabilityReduction" />
  ) : (
    'Excess Reduction'
  );

  const detail =
    quantity > 1
      ? `$${liabilityAmount.toFixed(2)} x ${quantity} days`
      : `$${liabilityAmount.toFixed(2)} x 1 day`;

  return {
    label,
    detail,
    price: formattedTotalLiabilityAmount,
  };
};
