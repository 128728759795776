import React from 'react';
import { FormattedMessage } from '../reactIntl';
import { formatMoney } from '../currency';
import { types as sdkTypes } from '../sdkLoader';
import { LINE_ITEM_DISCOUNT } from '../types';

const { Money } = sdkTypes;

// Validate the assumption that the discount exists and the amount
// is zero or negative.
const isValidDiscount = discountLineItem => {
  return (
    discountLineItem &&
    discountLineItem.lineTotal.amount < 0 &&
    discountLineItem.lineTotal instanceof Money
  );
};

export const lineItemDiscountCodeMaybe = (transaction, intl) => {
  // there is no intl possible for the PDF parent component using react-pdf
  // https://github.com/diegomura/react-pdf/issues/2354

  const discountCodeLineItem = transaction.attributes.lineItems.find(
    item => item.code === LINE_ITEM_DISCOUNT && !item.reversal
  );

  if (discountCodeLineItem) {
    const discount = discountCodeLineItem.lineTotal;
    const discountCodeLabel = !!intl ? (
      <FormattedMessage id="BookingBreakdown.discountCode" />
    ) : (
      'Discount code'
    );

    const showDiscount = isValidDiscount(discountCodeLineItem);

    const formattedDiscountCode = !!intl
      ? formatMoney(intl, discount)
      : `$${(discount.amount / 100).toFixed(2)}`;
    if (showDiscount) {
      return { label: discountCodeLabel, price: formattedDiscountCode, showDiscount: true };
    }
  }
  return { label: null, price: null, showDiscount: false };
};
