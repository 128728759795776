import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import css from './SectionMusteringSteps.module.css';

import step1Image from './images/contact-book.svg';
import step2Image from './images/checkout.svg';
import step3Image from './images/profit.svg';

const SectionMusteringSteps = props => {
  const { rootClassName, className, isAprilPromotion } = props;

  const classes = classNames(rootClassName || css.root, className);

  return (
    <div className={classes}>
      <h2> What you need to do </h2>
      <h3> Getting in on the muster is as easy as 1, 2, 3. </h3>
      <div className={css.stepsContainer}>
        <div className={css.step}>
          <img src={step1Image} alt="" role="presentation" className={css.icon} />
          <div className={css.stepHeadingAndWords}>
            <h3 className={css.stepNumHeading}>1.</h3>
            <div>
              <p className={css.stepWords}>Contact your mate with a trailer.</p>
            </div>
          </div>
        </div>
        <div className={css.step}>
          <img src={step2Image} alt="" role="presentation" className={css.icon} />
          <div className={css.stepHeadingAndWords}>
            <h3 className={css.stepNumHeading}>2.</h3>
            <div>
              <p className={css.stepWords}>
                Your mate signs up, lists their trailer and enters your name and email address as
                their referrer.
              </p>
            </div>
          </div>
        </div>
        <div className={css.step}>
          <img src={step3Image} alt="" role="presentation" className={css.icon} />
          <div className={css.stepHeadingAndWords}>
            <h3 className={css.stepNumHeading}>3.</h3>
            <div>
              <p className={css.stepWords}>
                We will be in contact with you – you'll earn {isAprilPromotion ? '$100' : '$50'}{' '}
                after their first hire.{' '}
                {isAprilPromotion
                  ? 'That’s right – this April only, you can muster up $100 cash.'
                  : ''}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

SectionMusteringSteps.defaultProps = { rootClassName: null, className: null };

const { string } = PropTypes;

SectionMusteringSteps.propTypes = {
  rootClassName: string,
  className: string,
};

export default SectionMusteringSteps;
