import React from 'react';
import { bool } from 'prop-types';
import { intlShape } from '../../util/reactIntl';
import { propTypes } from '../../util/types';
import { lineItemLiabilityReductionMaybe } from '../../util/bookingBreakdown/lineItemLiabilityReductionMaybe';
import config from '../../config';

import css from './BookingBreakdown.module.css';

const LineItemLiabilityReductionMaybe = props => {
  const { transaction, isCustomer, intl } = props;

  const { label, price, detail } = lineItemLiabilityReductionMaybe(transaction, intl);

  const showLiability =
    config.custom.featureToggles[config.env].liabilitySelection && !!label && !!price && !!detail;
  if (!showLiability) {
    return null;
  }

  return isCustomer && showLiability ? (
    <>
      <div className={css.lineItem}>
        <span className={css.itemLabel}>{label}</span>
        <span className={css.itemValue}>{`${price}`}</span>
      </div>
      <span className={css.itemLabel}>{detail}</span>
    </>
  ) : null;
};

LineItemLiabilityReductionMaybe.propTypes = {
  transaction: propTypes.transaction.isRequired,
  isCustomer: bool.isRequired,
  intl: intlShape.isRequired,
};

export default LineItemLiabilityReductionMaybe;
