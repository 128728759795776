import config from '../config';

const isServer = typeof window === 'undefined';

/**
 * Generate a consistent hash for a string
 */
const hashString = str => {
  let hash = 0;
  for (let i = 0; i < str.length; i++) {
    const char = str.charCodeAt(i);
    hash = (hash << 5) - hash + char;
    hash = hash & hash; // Convert to 32-bit integer
  }
  return Math.abs(hash);
};

/**
 * Get a consistent variant for a feature based on an identifier
 * Stores the variant in localStorage to maintain consistency
 */
export const getFeatureVariant = (featureName, userId) => {
  // Always check for an existing stored variant first
  if (!isServer) {
    const storedVariant = localStorage.getItem(`ab_test_${featureName}`);
    if (storedVariant) {
      return storedVariant;
    }
  }

  // If no stored variant, generate one based on the appropriate identifier
  const identifier = userId || getSessionId();
  const hash = hashString(`${featureName}-${identifier}`);
  const variant = hash % 2 === 0 ? 'control' : 'variant';

  if (!isServer) {
    localStorage.setItem(`ab_test_${featureName}`, variant);
  }

  return variant;
};

/**
 * Get a session ID for anonymous users
 */
export const getSessionId = () => {
  if (isServer) return 'server-side';

  let sessionId = localStorage.getItem('session_id');
  if (!sessionId) {
    sessionId =
      Math.random()
        .toString(36)
        .substring(2) + Date.now().toString(36);
    localStorage.setItem('session_id', sessionId);
  }
  return sessionId;
};

/**
 * Track feature exposure in GA4
 */
export const trackFeatureExposure = (featureName, identifier) => {
  const variant = getFeatureVariant(featureName, identifier);
  const userType = identifier ? 'logged_in' : 'anonymous';

  if (!isServer) {
    window.gtag?.('event', 'feature_exposure', {
      feature_name: featureName,
      variant: variant,
      user_type: userType,
      identifier: identifier || getSessionId(),
    });
  }
};

/**
 * Determine if a feature should be shown based on the user's status
 */
export const shouldShowFeature = (featureName, userId) => {
  // Check if feature is enabled in config
  const featureConfig = config.custom.featureToggles[config.env].abTests?.[featureName];
  if (!featureConfig?.enabled) {
    return false;
  }

  // Get identifier (prefer userId if available, otherwise use session ID)
  const identifier = userId || getSessionId();

  // Get variant and determine visibility
  const variant = getFeatureVariant(featureName, identifier);
  const shouldShow = variant === 'variant';

  // Track exposure if feature is shown
  if (shouldShow) {
    trackFeatureExposure(featureName, identifier);
  }

  return shouldShow;
};
