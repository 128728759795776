import React from 'react';
import { FormattedMessage } from '../reactIntl';
import { formatMoney } from '../currency';
import { LINE_ITEM_CUSTOMER_COMMISSION } from '../types';
import {
  TRANSITION_ABORT,
  TRANSITION_ABORT_BY_CUSTOMER,
  TRANSITION_DECLINE,
  TRANSITION_EXPIRE,
} from '../transaction';

export const lineItemCustomerCommissionRefundMaybe = (transaction, isCustomer, intl) => {
  // there is no intl possible for the PDF parent component using react-pdf
  // https://github.com/diegomura/react-pdf/issues/2354

  const refund = transaction.attributes.lineItems.find(
    item => item.code === LINE_ITEM_CUSTOMER_COMMISSION && item.reversal
  );

  const showRefund =
    transaction.attributes.lastTransition !== TRANSITION_EXPIRE &&
    transaction.attributes.lastTransition !== TRANSITION_DECLINE &&
    transaction.attributes.lastTransition !== TRANSITION_ABORT &&
    transaction.attributes.lastTransition !== TRANSITION_ABORT_BY_CUSTOMER;

  const customerCommissionRefundLabel = !!intl ? (
    <FormattedMessage id="BookingBreakdown.refundCustomerFee" />
  ) : (
    'Refund service fee'
  );
  const customerCommissionCancelLabel = !!intl ? (
    <FormattedMessage id="BookingBreakdown.cancelCustomerFee" />
  ) : (
    'Cancel service fee'
  );

  if (isCustomer && refund) {
    const formattedCustomerCommissionRefund = !!intl
      ? formatMoney(intl, refund.lineTotal)
      : `$${(refund.lineTotal.amount / 100).toFixed(2)}`;
    return {
      label: showRefund ? customerCommissionRefundLabel : customerCommissionCancelLabel,
      price: formattedCustomerCommissionRefund,
    };
  }
  return {
    label: null,
    price: null,
  };
};
