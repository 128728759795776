import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { propTypes } from '../../util/types';
import { ListingCard, PaginationLinks } from '../../components';
import { getSessionId, shouldShowFeature } from '../../util/abTesting';
import css from './SearchResultsPanel.module.css';
import { ensureUser } from '../../util/data';

const SearchResultsPanel = props => {
  const {
    className,
    rootClassName,
    listings,
    pagination,
    search,
    setActiveListing,
    currentUser,
  } = props;
  const classes = classNames(rootClassName || css.root, className);

  // Pass null userId for anonymous users
  const isSuperOwnerSortingEnabled = shouldShowFeature(
    'superOwnerSorting',
    currentUser?.id?.uuid || null
  );

  const paginationLinks =
    pagination && pagination.totalPages > 1 ? (
      <PaginationLinks
        className={css.pagination}
        pageName="SearchPage"
        pageSearchParams={search}
        pagination={pagination}
      />
    ) : null;

  // Panel width relative to the viewport
  const panelMediumWidth = 50;
  const panelLargeWidth = 62.5;
  const cardRenderSizes = [
    '(max-width: 767px) 100vw',
    `(max-width: 1023px) ${panelMediumWidth}vw`,
    `(max-width: 1920px) ${panelLargeWidth / 2}vw`,
    `${panelLargeWidth / 3}vw`,
  ].join(', ');

  // Memoize the sorted listings to prevent unnecessary re-calculation on re-renders
  const sortedListings = useMemo(() => {
    return listings.reduce(
      (acc, listing) => {
        const isPremium = listing.attributes.publicData.subscription === 'premium';
        const author = ensureUser(listing.author);
        const isSuperOwner = author.attributes.profile.publicData?.superOwner || false;

        // Group the listings based on premium and superOwner status
        if (isPremium && isSuperOwner) {
          acc.premiumSuperOwner.push(listing);
        } else if (isPremium) {
          acc.premium.push(listing);
        } else if (isSuperOwner) {
          acc.superOwner.push(listing);
        } else {
          acc.regular.push(listing);
        }
        return acc;
      },
      { premiumSuperOwner: [], premium: [], superOwner: [], regular: [] }
    );
  }, [listings, isSuperOwnerSortingEnabled]);

  // Combine listings in order based on A/B test
  const finalSortedListings = isSuperOwnerSortingEnabled
    ? [
        ...sortedListings.premiumSuperOwner, // Premium and SuperOwner first
        ...sortedListings.premium, // Then Premium
        ...sortedListings.superOwner, // Then SuperOwner
        ...sortedListings.regular, // Finally Regular listings
      ]
    : listings;

  return (
    <div className={classes}>
      <div className={css.listingCards}>
        {finalSortedListings.map(l => (
          <ListingCard
            className={css.listingCard}
            key={l.id.uuid}
            listing={l}
            renderSizes={cardRenderSizes}
            setActiveListing={setActiveListing}
          />
        ))}
        {props.children}
      </div>
      {paginationLinks}
    </div>
  );
};

SearchResultsPanel.defaultProps = {
  children: null,
  className: null,
  listings: [],
  pagination: null,
  rootClassName: null,
  search: null,
  currentUser: null,
};

const { array, node, object, string, bool } = PropTypes;

SearchResultsPanel.propTypes = {
  children: node,
  className: string,
  listings: array,
  pagination: propTypes.pagination,
  rootClassName: string,
  search: object,
  currentUser: propTypes.currentUser,
};

export default SearchResultsPanel;
