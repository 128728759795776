import React from 'react';
import { Text, View } from '@react-pdf/renderer';
import { intlShape } from '../../util/reactIntl';
import { propTypes } from '../../util/types';
import { lineItemCustomerCommissionMaybe } from '../../util/bookingBreakdown/lineItemCustomerCommissionMaybe';
import { styles } from '../Invoice/InvoiceStyles';
import { bool } from 'prop-types';

const styleSheet = styles;

const LineItemCustomerCommissionMaybePdf = props => {
  const { transaction, isCustomer, intl } = props;
  const { label, price } = lineItemCustomerCommissionMaybe(transaction, isCustomer, intl);

  return isCustomer && price ? (
    <>
      <View
        style={[
          styleSheet.row,
          styleSheet.borderTop,
          styleSheet.marginAboveLarge,
          styleSheet.paddingAboveLarge,
        ]}
      >
        <Text style={[styleSheet.boldFont, styleSheet.tColumnLeft]}>
          Local Trailer Hire Service Fee
        </Text>
        <View style={styleSheet.tColumnCentre}>
          <Text>{label}</Text>
        </View>
        <View style={styleSheet.rightAligned}>
          <Text>{price}</Text>
        </View>
      </View>
      <View style={[styleSheet.tColumnLeft, styleSheet.smallFont]}>
        <Text>
          Local Trailer Hire charges a​ service​ fee for use of the Local Trailer Hire online
          platform.
        </Text>
      </View>
    </>
  ) : null;
};

LineItemCustomerCommissionMaybePdf.propTypes = {
  transaction: propTypes.transaction.isRequired,
  isCustomer: bool.isRequired,
  intl: intlShape,
};

export default LineItemCustomerCommissionMaybePdf;
