import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import css from './SectionSafeHiringTips.module.css';

const SectionSafeHiringTipsComponent = props => {
  const { rootClassName, className } = props;
  const classes = classNames(rootClassName || className);

  return (
    <React.Fragment>
      <div className={css.safeHiringTipsContent}>
        <h3 className={css.heading}>Safety</h3>
        <p>
          The following tips for safe trailer usage are general in nature and it's essential to
          consult your vehicle's manual before towing. Don't hesitate to seek advice and tips from
          the trailer owner specific to the one you are hiring. Always prioritise safety and take
          necessary precautions to ensure a smooth and secure towing experience.
        </p>
        <div id="trailerSelection"></div>
        <div className={css.safeTipSection}>
          <h4 className={css.subheading}>Choosing a trailer</h4>
          <ul className={css.list}>
            <li>
              <span className={css.bold}>Check your vehicle's towing capacity:</span> Ensure that
              your vehicle is capable of towing the weight and size of the trailer you plan to use.
              Refer to your vehicle's manual to determine the maximum towing capacity and ensure it
              exceeds the trailer's weight.
            </li>
          </ul>
        </div>
        <div id="pickUp"></div>
        <div className={css.safeTipSection}>
          <h4 className={css.subheading}>Pick-up</h4>
          <ul className={css.list}>
            <li>
              <span className={css.bold}>Check tyre pressure:</span> Inspect the trailer's tyres for
              proper inflation. Ensure they are in good condition and have adequate tread depth to
              provide sufficient traction and stability.
            </li>
            <li>
              <span className={css.bold}>Double-check the hitch connection:</span> After attaching
              the trailer to the hitch, give it a firm tug or gentle push in different directions to
              confirm that it is securely fastened. The trailer should not move or sway excessively.
              If there is any noticeable movement or play, recheck the hitch connection, making sure
              it is properly latched or secured according to the manufacturer's instructions.
            </li>
            <li>
              <span className={css.bold}>Check the handbrake is disengaged:</span> Before driving,
              ensure the trailer's handbrake is off and fully disengaged.
            </li>
            <li>
              <span className={css.bold}>Check the braking system is engaged:</span> Mechanical or
              hydraulic brakes are activated via compression when the trailer pushes against the
              back of the vehicle during braking. To engage the brake system, ensure that the metal
              spacer is flicked "open" to allow the brake system to compress. If you need to reverse
              the trailer, you can flick it "closed" to disengage the braking system temporarily.
            </li>
            <li>
              <span className={css.bold}>Secure the jockey wheel:</span> If your trailer has a
              jockey wheel, make sure it is properly stowed in the upright position and securely
              fastened. This prevents it from dragging or causing damage while towing.
            </li>
            <li>
              <span className={css.bold}>Connect safety chains:</span> Attach safety chains from the
              trailer to the tow vehicle's hitch. Cross the chains under the hitch to form an X
              pattern, allowing them to catch the trailer in case it disconnects.
            </li>
            <li>
              <span className={css.bold}>Connect the trailer lights:</span> Connect the trailer's
              electrical wiring to the tow vehicle's electrical system to ensure the trailer's
              indicators and brake lights function properly. Test the lights before you start
              towing.
            </li>
            <li>
              <span className={css.bold}>Take pre-drive photos:</span> Before towing, snap pictures
              of the trailer from all sides. These photos serve as documentation of the trailer's
              condition when you rented it.
            </li>
          </ul>
        </div>
        <div id="loading"></div>
        <div className={css.safeTipSection}>
          <h4 className={css.subheading}>Loading</h4>
          <ul className={css.list}>
            <li>
              <span className={css.bold}>Properly distribute the load:</span> Ensure the weight
              inside the trailer is evenly distributed. Heavier items should be placed at the bottom
              and centered, with lighter items on top. Avoid overloading the trailer, as it can
              affect stability and braking.
            </li>
            <li>
              <span className={css.bold}>Secure the cargo:</span> Make sure all items inside the
              trailer are securely tied down or strapped to prevent shifting during transit. Loose
              cargo can create an imbalance and affect the trailer's stability.
            </li>
          </ul>
        </div>
        <div id="driving"></div>
        <div className={css.safeTipSection}>
          <h4 className={css.subheading}>On the road</h4>
          <ul className={css.list}>
            <li>
              <span className={css.bold}>Adjust your driving style:</span> Be mindful of the
              additional length and weight you are towing. Allow for longer stopping distances,
              avoid sudden manoeuvres, and drive at a moderate speed that complies with traffic and
              weather conditions.
            </li>
            <li>
              <span className={css.bold}>Be cautious when changing lanes:</span> The trailer may
              affect your vehicle's blind spots, so use your mirrors and check blind spots carefully
              before changing lanes.
            </li>
            <li>
              <span className={css.bold}>Monitor the trailer:</span> Regularly check your mirrors to
              ensure the trailer is stable, cargo is secure, and there's no excessive wobbling or
              signs of trouble. If you notice any concerning issues, such as loose cargo, unusual
              smells or sounds, or potential tyre problems, it is recommended to pull over at a safe
              location to verify and address these concerns.
            </li>
          </ul>
        </div>
      </div>
    </React.Fragment>
  );
};

SectionSafeHiringTipsComponent.defaultProps = {
  rootClassName: null,
  className: null,
};

SectionSafeHiringTipsComponent.defaultProps = { rootClassName: null, className: null };

const { string } = PropTypes;

SectionSafeHiringTipsComponent.propTypes = {
  rootClassName: string,
  className: string,
};

export default SectionSafeHiringTipsComponent;
