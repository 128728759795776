import React from 'react';
import PropTypes, { bool, func } from 'prop-types';
import { FormattedMessage, intlShape, injectIntl } from '../../util/reactIntl';
import classNames from 'classnames';
import { Modal, NamedLink } from '..';
import { createSlug } from '../../util/urlHelpers';

import css from './ManageAvailabilityModal.module.css';
import { propTypes } from '../../util/types';

const ManageAvailabilityModal = props => {
  const {
    className,
    rootClassName,
    id,
    intl,
    isOpen,
    onCloseModal,
    onManageDisableScrolling,
    currentListing,
  } = props;
  const classes = classNames(rootClassName || css.root, className);

  const closeButtonMessage = intl.formatMessage({ id: 'ManageAvailabilityModal.later' });

  const slug = createSlug(currentListing.attributes.title);

  return (
    <Modal
      id={id}
      containerClassName={classes}
      isOpen={isOpen}
      onClose={onCloseModal}
      onManageDisableScrolling={onManageDisableScrolling}
      closeButtonMessage={closeButtonMessage}
    >
      <p className={css.modalTitle}>
        <FormattedMessage id="ManageAvailabilityModal.title" />
      </p>
      <p className={css.modalMessage}>
        <FormattedMessage id="ManageAvailabilityModal.declinedSuccess" />
      </p>
      <p className={css.modalMessage}>
        <FormattedMessage id="ManageAvailabilityModal.description" />
      </p>
      <NamedLink
        className={css.manageLink}
        name="EditListingPage"
        params={{
          id: currentListing.id.uuid,
          slug,
          type: 'edit',
          tab: 'availability',
        }}
      >
        <FormattedMessage id="ManageAvailabilityModal.link" />
      </NamedLink>
    </Modal>
  );
};

const { string } = PropTypes;

ManageAvailabilityModal.defaultProps = {
  className: null,
  rootClassName: null,
  currentListing: null,
};

ManageAvailabilityModal.propTypes = {
  className: string,
  rootClassName: string,
  intl: intlShape.isRequired,
  isOpen: bool,
  onCloseModal: func,
  onManageDisableScrolling: func,
  currentListing: propTypes.listing,
};

export default injectIntl(ManageAvailabilityModal);
