import React from 'react';
import PropTypes from 'prop-types';

import css from './PillTag.module.css';

const PillTag = ({ type, text }) => {
  const pillStyles = {
    standard: {
      backgroundColor: 'var(--matterColorBright)',
      borderColor: 'var(--marketplaceColorDark)',
    },
    info: {
      backgroundColor: 'var(--matterColorAnti)',
      borderColor: 'var(--matterColor)',
    },
    warning: {
      backgroundColor: 'var(--failColorLight)',
      borderColor: 'var(--failColor)',
    },
    success: {
      backgroundColor: 'var(--successColorLight)',
      borderColor: 'var(--successColor)',
    },
  };

  const { backgroundColor, borderColor } = pillStyles[type] || pillStyles.standard;

  return (
    <div className={css.root} style={{ backgroundColor, borderColor }}>
      {text}
    </div>
  );
};

const { string } = PropTypes;

PillTag.propTypes = {
  rootClassName: string,
  className: string,
  text: string.isRequired,
  type: PropTypes.oneOf(['standard', 'information', 'success', 'warning']),
};

export default PillTag;
